import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledInputCheckbox = styled.label`
  margin-right: 1em;
`

export const InputCheckbox = ({ name, label, checked, click, onChange }) => {
  return (
    <StyledInputCheckbox>
      {label}:
      <input
        onChange={onChange}
        checked={checked}
        type="checkbox"
        name={name}
      />
    </StyledInputCheckbox>
  )
}

InputCheckbox.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  checked: PropTypes.bool,
  click: PropTypes.func,
}
