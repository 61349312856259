import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { queryDictBattery } from '../../graphql'
import { DropDownVariables } from './DropDownVariables'

export const SelectNpower = ({ setNpower, setCapacityah, setModelAKB }) => {
  const [arrayElements, setArrayElements] = useState()
  const [element, setElement] = useState()

  useEffect(() => {
    if (!element) return
    setNpower(element.npower)
    setCapacityah(element.capacityah)
    setModelAKB(element.title)
  }, [element, setCapacityah, setModelAKB, setNpower])

  useQuery(queryDictBattery.query, {
    onCompleted: (data) => {
      setArrayElements(data[queryDictBattery.dataString])
    },
  })

  if (!arrayElements) return <></>

  return (
    <DropDownVariables
      title="Номинальная мощность"
      listVar={arrayElements}
      setSelected={setElement}
    />
  )
}

SelectNpower.propTypes = { setNpower: PropTypes.func }
