import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ItemMeter } from './ItemMeter'

const StyledListObjects = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  /* align-items: left; */

  /* @media (max-width: 840px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  } */
`

const StyledObject = styled.div`
  ${
    '' /* display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.2em; */
  }

  display: grid;
  justify-items: center;
  align-items: center;
  justify-content: space-evenly;
  align-content: space-evenly;
  grid-template-columns: 30px 70px 180px 80px 30px 230px 30px 200px 200px 70px;
  ${'' /* grid-auto-rows: 25px; */}
  border-bottom: 1px solid #e1e5eb;
`
const StyledTitle = styled.span`
  margin-right: 1em;
`

export const ListMeters = ({ data }) => {
  return (
    <StyledListObjects>
      <StyledObject>
        <StyledTitle>objectid</StyledTitle>
        <StyledTitle>id</StyledTitle>
        <StyledTitle>P</StyledTitle>
        <StyledTitle>Q</StyledTitle>
        <StyledTitle>S</StyledTitle>
      </StyledObject>
      {data.map((item, i) => {
        return <ItemMeter key={i} meter={item} />
      })}
    </StyledListObjects>
  )
}

ListMeters.propTypes = { data: PropTypes.arrayOf(PropTypes.object) }
ListMeters.defaultTypes = { data: [] }
