import React from 'react'
import PropTypes from 'prop-types'

export const CheckBox = ({ name, label, checked, click, dataContext }) => {
  const handleClick = () => {
    click({ dataContext, checked })
  }

  return (
    <label>
      <input
        className="check-box"
        onChange={handleClick}
        checked={checked}
        type="checkbox"
        name={name}
      />
      {label}
    </label>
  )
}

CheckBox.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  classN: PropTypes.string,
  checked: PropTypes.bool,
  click: PropTypes.func,
  dataContext: PropTypes.object,
}
