import React, { useState, useEffect } from 'react'
import { useBillingObject } from '../../hooks'
import {
  subInventorydbMeters,
  subObjectID,
  queryObjectBilling,
  // queryUserData,
} from '../../graphql'
import { useSubscription } from '@apollo/client'
import {
  ObjectCard,
  MeterCard,
  ChannelsPanel,
  CardForComments,
} from '../../components'
import styled from 'styled-components'
// import { useAuth0 } from '@auth0/auth0-react'

const StyledDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  grid-gap: 1em;

  @media (max-width: 840px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
`

// import { CurrentMeter } from './CurrentMeter'

export const ObjectConfig = ({ match }) => {
  // const { user } = useAuth0()
  // const [email, setemail] = useState()
  // const [userdata, setuserData] = useState()
  const [id] = useState(+match.params.id)
  const [object, setObject] = useState({})
  const [meters, setMeters] = useState([])
  // const [channels, setChannels] = useState()
  const [showChannels, setShowChannels] = useState(false)
  // const [addressMeter, setAdresMeter] = useState()
  const [meterAddress, setMeterAddress] = useState()
  const [currentMeter, setCurrentMeter] = useState()

  // const billingcode = useMemo(() => {
  //   if (!object) return
  //   return object.billingcode
  // }, [object])

  const { data: dataMeter } = useSubscription(subInventorydbMeters.query, {
    variables: { id },
  })

  // const dataBilling = useBillingToken({
  //   queryBilling: queryObjectBilling,
  //   code: String(encodeURI(billingcode)),
  // })

  const dataBilling = useBillingObject({
    billingQuery: queryObjectBilling,
    code: String(encodeURI(object.billingcode)),
  })

  const { data: dataObject } = useSubscription(subObjectID.query, {
    variables: { id },
  })

  useEffect(() => {
    if (!dataBilling) return
    if (!dataObject) return
    if (dataObject[subObjectID.dataString].length === 0) return

    const object = dataObject[subObjectID.dataString][0]
    const obj = Object.assign({}, dataBilling[0], object)
    setObject(obj)
  }, [dataObject, dataBilling])

  useEffect(() => {
    if (!dataMeter) return
    if (dataMeter[subInventorydbMeters.dataString].length === 0) return
    const arr = dataMeter[subInventorydbMeters.dataString]
    setMeters(arr)

    // set meters Address
    const address = arr.map((item) => item.address)
    setMeterAddress(address)
  }, [dataMeter])

  const updateDataObject = () => {
    // getData()
  }

  return (
    <div>
      {object && (
        <ObjectCard
          object={object}
          updateDataObject={updateDataObject}
          meterAddress={meterAddress}
          meters={meters}
        />
      )}
      {object && <CardForComments id={object.id} addinfo={object.addinfo} />}

      <StyledDiv>
        {object &&
          meters.map((item, i) => {
            return (
              <MeterCard
                key={i}
                meter={item}
                // onChangeSelectMeter={onChangeSelectMeter}
                meters={meters}
                // fSetChannels={fSetChannels}
                objectID={id}
                regionid={object.regionid}
                // fSetAddress={fSetAddress}
                setShowChannels={setShowChannels}
                setCurrentMeter={setCurrentMeter}
              />
            )
          })}
      </StyledDiv>

      {showChannels && <ChannelsPanel meter={currentMeter} />}
    </div>
  )
}
