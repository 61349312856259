import React from 'react'
import { Route } from 'react-router-dom'
import { Loading } from '../components'
import { useKeycloak } from '@react-keycloak/web'

export const ProtectedRoute = ({
  component: Component,
  layout: Layout,
  ...args
}) => {
  const { keycloak } = useKeycloak()

  const isLoggedIn = keycloak.authenticated

  const component = (props) => {
    return (
      <Layout {...props}>
        <Component {...props} />
      </Layout>
    )
  }

  if (isLoggedIn === false) {
    keycloak.login()
  }
  if (!isLoggedIn) return <Loading />

  return <Route component={component} {...args} />
}
