import React, { useContext } from 'react'
// import PropTypes from 'prop-types'
// import { useAuth0 } from '@auth0/auth0-react'
import { Button, Card, CardBody, CardHeader } from '../../components'
import { ContextUser } from '../../context'
// import { useRoleAuth } from '../../hooks'
import { useKeycloak } from '@react-keycloak/web'

export const UserProfile = () => {
  const { keycloak } = useKeycloak()
  const { email, customer } = useContext(ContextUser)
  // const { email } = context
  // const { logout } = useAuth0()
  // const [userData, setUserData] = useState(user)
  // const userRole = useRoleAuth()
  // useEffect(() => {
  //   setUserData(user)
  // }, [user])
  return (
    <div>
      <Card>
        <CardHeader className="border-bottom"></CardHeader>
        <CardBody>email: {email}</CardBody>
        <CardBody>{customer}</CardBody>
      </Card>
      <Card>
        <CardHeader className="border-bottom"></CardHeader>
        <CardBody>
          {!keycloak.authenticated && (
            <Button
              type="button"
              className="text-blue-800"
              onClick={() => keycloak.login()}>
              Login
            </Button>
          )}
          {keycloak.authenticated && (
            <Button
              type="button"
              className="text-blue-800"
              onClick={() => keycloak.logout()}>
              Logout ({keycloak.tokenParsed.preferred_username})
            </Button>
          )}
        </CardBody>
      </Card>
    </div>
  )
}

// UserProfile.propTypes = {}
