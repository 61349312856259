// import React from 'react'
import PropTypes from 'prop-types'
import { store } from 'react-notifications-component'

export const Notification = ({ title, message, type }) => {
  return store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: 'top',
    container: 'top-full',
    animationIn: ['animate__animated', 'animate__zoomIn'],
    animationOut: ['animate__animated', 'animate__zoomOut'],
    dismiss: {
      duration: 2000,
      onScreen: false,
    },
  })
}

Notification.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string,
}
