import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// import { translateStatusColor } from '../../utils'
// import { PhoneObject } from './PhoneObject'
// import { EditItemObject } from './EditItemObject'
// import moment from 'moment'
// import { useTranslation } from 'react-i18next'
// import { Button } from '..'
// import { Link } from 'react-router-dom'

const StyledObject = styled.div`
  ${
    '' /* display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.2em; */
  }

  display: grid;
  justify-items: center;
  align-items: center;
  justify-content: space-evenly;
  align-content: space-evenly;
  grid-template-columns: 30px 70px 180px 80px 30px 230px 30px 200px 200px 70px;
  ${'' /* grid-auto-rows: 25px; */}
  border-bottom: 1px solid #e1e5eb;
`

// const StyledCircle = styled.div`
//   display: inline-block;
//   margin-right: 1em;
//   background: ${(props) => props.inputColor || 'palevioletred'};
//   border: 3px solid #e1e5eb;
//   border-radius: 50%;
//   /* box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.3); */
//   height: 1.3em;
//   width: 1.3em;
// `

const StyledTitle = styled.span`
  margin-right: 1em;
`
const StyledP = styled.span`
  margin-right: 1em;
  background: ${(props) => props.background};
`

const StyledQ = styled.span`
  margin-right: 1em;
  background: ${(props) => props.background};
`

// const StyledTitleA = styled.a`
//   margin-right: 1em;
// `

export const ItemMeter = ({ meter }) => {
  // const { t } = useTranslation()
  // const [editable, setEditable] = useState(false)
  const total_p = meter.rs_channel.rs_pu_array[0].total_p
  const total_q = meter.rs_channel.rs_pu_array[0].total_q
  const total_s = meter.rs_channel.rs_pu_array[0].total_s

  const checkP = (p) => {
    if (p < 0) {
      return 'red'
    }
  }

  const checkQ = (q) => {
    if (q >= 0) {
      return 'red'
    }
  }

  const checkS = (s, p) => {
    if (s < p) {
      return 'red'
    }
  }
  // if (editable) {
  //   return <EditItemObject meter={meter} setEditable={setEditable} />
  // } else {
  return (
    <StyledObject>
      <StyledTitle>{meter.parentid}</StyledTitle>
      <StyledTitle>{meter.id}</StyledTitle>

      <StyledP background={checkP(total_p)}>{total_p}</StyledP>
      <StyledQ background={checkQ(total_q)}>{total_q}</StyledQ>
      <StyledQ background={checkS(total_s, total_p)}>{total_s}</StyledQ>
    </StyledObject>
  )
  // }
}

ItemMeter.propTypes = { meter: PropTypes.object }
