import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  // Button,
  Card,
  CardBody,
  CardHeader,
  // CurrentMeter,
  // Modal,
  ButtonPanelEditing,
} from '../../components'
import { queryInventorydbCustomer } from '../../graphql'
import { useQuery } from '@apollo/client'

import { EditChannelCardBody } from './EditChannelCardBody'

const StyledCardHeader = styled(CardHeader)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`

export const ChannelCard = ({ channel, addressMeter }) => {
  const [customers, setCustomers] = useState()
  const [customer, setCustomer] = useState()
  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)

  const statusChannel = [
    { id: 0, title: 'зарегистрирован, идет опрос' },
    { id: 1, title: 'зарегистрирован, не опрашивается' },
    { id: 2, title: 'не зарегистрирован, опрашивается' },
    { id: 3, title: 'не зарегистрирован, не опрашивается' },
  ]

  const setStatusChannel = (id) => {
    if (id === null) return
    const t = statusChannel.find((item) => {
      return item.id === id
    })
    return t.title
  }

  useEffect(() => {
    if (!customers) return
    if (!channel.customerid) {
      setCustomer('')
      return
    }
    setCustomer(customers.find((item) => item.id === channel.customerid).title)
  }, [customers, channel])

  const { data: dataCustomer } = useQuery(queryInventorydbCustomer.query)

  useEffect(() => {
    if (!dataCustomer) return
    const arr = dataCustomer[queryInventorydbCustomer.dataString]
    setCustomers(arr)
  }, [dataCustomer])

  // const choicaCustomer = (id) => {
  //   if (!id) return ''
  //   return customers.find((item) => item.id === id).title
  // }

  const openEdit = () => {
    setEditing(true)
  }

  const cancelEdit = () => {
    setEditing(false)
    setSaving(false)
  }

  const saveEdit = () => {
    setSaving(true)
  }

  return (
    <Card>
      <StyledCardHeader className="border-bottom">
        Номер канала: {channel.channel_num}
        {'  '}
        Счетчик адрес:{addressMeter}
        <ButtonPanelEditing
          editing={editing}
          openEdit={openEdit}
          cancelEdit={cancelEdit}
          saveEdit={saveEdit}
        />
      </StyledCardHeader>

      {!editing ? (
        <CardBody>
          <p>Оператор: {customer}</p>
          <p>Код: {channel.customercode}</p>
          <p>Мощность: {channel.fpower}</p>
          <p>
            Используется:{' '}
            <input readOnly checked={channel.inuse} type="checkbox" />
          </p>
          <p>Статус: {setStatusChannel(channel.statusid)}</p>
        </CardBody>
      ) : (
        <EditChannelCardBody
          saving={saving}
          channel={channel}
          cancelEdit={cancelEdit}
          customers={customers}
          statusChannel={statusChannel}
        />
      )}
    </Card>
  )
}

ChannelCard.propTypes = {}
