import React from 'react'
import { Switch, BrowserRouter } from 'react-router-dom'
import { ProtectedRoute } from './auth'
import {
  ObjectConfig,
  Home,
  UserProfile,
  Objects,
  Meters,
  NewObject,
} from './views'
import { MainLayout } from './layouts'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { keycloak } from './auth'

const Routes = () => {
  return (
    <ReactKeycloakProvider authClient={keycloak}>
      <BrowserRouter>
        <Switch>
          <ProtectedRoute path="/" exact component={Home} layout={MainLayout} />
          {/* <Redirect exact push from="/" to="/configure" /> */}
          <ProtectedRoute
            path="/objects"
            component={Objects}
            layout={MainLayout}
          />
          <ProtectedRoute
            path="/meters"
            component={Meters}
            layout={MainLayout}
          />
          <ProtectedRoute
            path="/configure/:id"
            component={ObjectConfig}
            layout={MainLayout}
          />
          <ProtectedRoute
            path="/new-object"
            component={NewObject}
            layout={MainLayout}
          />
          <ProtectedRoute
            path="/user/:id"
            component={UserProfile}
            layout={MainLayout}
          />
        </Switch>
      </BrowserRouter>
    </ReactKeycloakProvider>
  )
}

export default Routes
