export const translateStatusid = (statusid) => {
  switch (statusid) {
    case 0:
      return 'Зарегистрирован, связь отсутствует'

    case 1:
      return 'Зарегистрирован, на связи'

    case 2:
      return 'Привязан'

    case 3:
      return 'Не привязан'

    default:
      return 'Неизвестное состояние'
  }
}
