import React, { useState, useEffect } from 'react'
import { Topbar, Sidebar, Loading } from '../../components'
import { NavState, ProviderUser } from '../../context'
import { queryUserData } from '../../graphql'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import { useKeycloak } from '@react-keycloak/web'

const StyledDivMainLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'topbar'
    'main';
  grid-template-rows: 45px auto;

  @media (min-width: 840px) {
    height: 100%;
    grid-template-areas:
      'topbar topbar'
      'sidebar main';
    grid-template-rows: 45px auto;
    grid-template-columns: 200px auto;
  }
`

const StyledDivGeedTopbar = styled(Topbar)`
  grid-area: topbar;
`
const StyledDivGeedSidebar = styled.div`
  display: none;
  @media (min-width: 840px) {
    grid-area: sidebar;
    display: block;
  }
`

const StyledDivGeedMain = styled.div`
  grid-area: main;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
  height: 100%;
  overflow: auto;
`

export const MainLayout = ({ children }) => {
  const [userdata, setuserData] = useState()

  const { keycloak } = useKeycloak()
  const [email, setemail] = useState()

  useEffect(() => {
    if (!keycloak) return
    if ('email' in keycloak.tokenParsed) setemail(keycloak.tokenParsed.email)
    console.log('email =', keycloak.tokenParsed)
  }, [keycloak])

  useQuery(queryUserData.query, {
    variables: { email },
    onCompleted: (data) => {
      if (data[queryUserData.dataString].length === 0) return
      setuserData(data[queryUserData.dataString][0])
    },
  })

  return !userdata ? (
    <Loading />
  ) : (
    <StyledDivMainLayout>
      <NavState>
        <StyledDivGeedTopbar />
        <StyledDivGeedSidebar>
          <ProviderUser value={userdata}>
            <Sidebar />
          </ProviderUser>
        </StyledDivGeedSidebar>
        <StyledDivGeedMain>
          <ProviderUser value={userdata}>{children}</ProviderUser>
        </StyledDivGeedMain>
      </NavState>
    </StyledDivMainLayout>
  )
}
