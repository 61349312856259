import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import {
  // TableWrapper,
  Card,
  CardBody,
  // InputSearch,
  // FilterCustomer,
  // FilterStatus,
  // FilterStatusSingle,
  // CheckBox,
  ListMeters,
} from '../../components'
import { queryChannelsLastData } from '../../graphql'
import { useQuery } from '@apollo/client'

export const Meters = () => {
  const [meters, setMeter] = useState()

  const { loading } = useQuery(queryChannelsLastData.query, {
    variables: { objectid: [1597, 1749] },
    onCompleted: (data) => {
      setMeter(data[queryChannelsLastData.dataString])
    },
  })

  if (loading) return <div>Loading...</div>

  return (
    <Card>
      <CardBody>{meters && <ListMeters data={meters} />}</CardBody>
    </Card>
  )
}

Meters.propTypes = {}
