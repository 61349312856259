import React, { useState, useEffect, useContext, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Notification } from '../../components'
import { ContextUser } from '../../context'
import { useMutation, useSubscription } from '@apollo/client'
import {
  mutationInstallerPhone,
  subObjectID,
  mutationObjectPhone,
} from '../../graphql'
import { Inp } from './Inp'

const StyledDiv = styled.div`
  width: 100%;
  /* margin-bottom: 1em; */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: start;
  }
`

const StyledSpan = styled.span`
  margin-right: 1em;
  font-weight: bold;

  @media (max-width: 640px) {
    margin-right: 0;
    margin-bottom: 0.5em;
  }
`

export const InputPhone = ({ objectid, resetPhone, setResetPhone }) => {
  const [phone, setphone] = useState('') //! phone from table modem
  const context = useContext(ContextUser)
  const [object, setObject] = useState()
  const [statusid, setStatusid] = useState()
  const [objecRegistered, setObjecRegistered] = useState()

  const { data: dataObject } = useSubscription(subObjectID.query, {
    variables: { id: objectid },
  })

  useEffect(() => {
    if (!dataObject) return
    if (dataObject[subObjectID.dataString].length === 0) return
    const object = dataObject[subObjectID.dataString][0]
    setObject(object)
    setStatusid(object.statusid)
    // if (object.statusid !== 3) {
    if (!object.phone) {
      setphone('')
    } else {
      setphone(object.phone)
    }
    // }

    const reg = () => {
      switch (object.statusid) {
        case 0:
        case 1:
        case 2:
          return true

        default:
          return false
      }
    }
    setObjecRegistered(reg)
  }, [dataObject])

  const savePhone = (p) => {
    setPhoneLog({
      variables: {
        email: context.email,
        userid: context.userid,
        eventtype: 200,
        jdata: `{"phone": "${p}", "objectid": ${objectid}}`,
      },
    })
  }

  // mutation number phone
  const [setPhoneLog, { data: dataLog }] = useMutation(
    mutationInstallerPhone.query
  )

  useEffect(() => {
    if (!dataLog) return
    return Notification({
      title: 'Изменения сохранены.',
      message: 'Запись телефона в userlog',
      type: 'info',
    })
  }, [dataLog])

  const [setPhoneModem, { data: dataModem }] = useMutation(
    mutationObjectPhone.query
  )

  const fetchPhoneModem = useCallback(() => {
    setPhoneModem({
      variables: {
        id: objectid,
        phone: object.temp_phone,
      },
    })
  }, [object, objectid, setPhoneModem])

  useEffect(() => {
    if (!statusid) return
    if (statusid === 2) {
      fetchPhoneModem()
    }
  }, [statusid, fetchPhoneModem])

  useEffect(() => {
    if (!dataModem) return
    return Notification({
      title: 'Изменения сохранены.',
      message: 'Номер телефона добавлен в теблицу modem',
      type: 'success',
    })
  }, [dataModem])

  const [resetPhoneModem, { data: resetModem }] = useMutation(
    mutationObjectPhone.query
  )

  useEffect(() => {
    if (!resetPhone) return
    setPhoneLog({
      variables: {
        email: context.email,
        userid: context.userid,
        eventtype: 201,
        jdata: `{"phone": "${phone}", "objectid": ${objectid}}`,
      },
    })
    resetPhoneModem({ variables: { id: objectid, phone: null } })
    setResetPhone(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPhone])

  useEffect(() => {
    if (!resetModem) return
    return Notification({
      title: 'Изменения сохранены.',
      message: 'номер телефона обнулен',
      type: 'warning',
    })
  }, [resetModem])

  return (
    <StyledDiv>
      <StyledSpan>Телефон:</StyledSpan>
      {object && (
        <Inp
          phone={object.phone}
          setphone={setphone}
          objecRegistered={objecRegistered}
          savePhone={savePhone}
        />
      )}
    </StyledDiv>
  )
}

InputPhone.propTypes = {
  resetPhone: PropTypes.bool,
  setResetPhone: PropTypes.func,
  objectid: PropTypes.number,
}
