import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Card } from '../../components'
// import { PanelCheckbox } from './PanelCheckbox'
import { useOnClickOutside } from '../../hooks'
import { PanelSearch } from './PanelSearch'

const StyledDRopDown = styled.div`
  width: 100%;
  min-height: 2rem;
`

const StyledTitle = styled.p`
  width: 100%;
  min-height: 2rem;
  border: 1px solid #e1e5eb;
  display: flex;
  align-items: center;
  @media (max-width: 640px) {
  }
`

const StyledMenu = styled.div`
  /* z-index: 99; */
  /* display: ${(props) => (props.open ? 'block' : 'none')}; */
  position: absolute;
  /* width: 100%; */
  max-height: ${(props) => (props.open ? '400px' : '0px')};
  transition: max-height 0.7s linear 0s;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 0.125rem 0.625rem rgba(90, 97, 105, 0.12);
`

const StyledMenuSearch = styled(Card)`
  width: 100%;
`

const StyledUlMenu = styled.ul`
  height: 200px;
  overflow: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0 0 0.625rem 0.625rem;
  box-shadow: 0 0.125rem 0.625rem rgba(90, 97, 105, 0.12);
`

const StyledLiMenuItem = styled.li`
  text-decoration: none;
  font-size: 0.85rem;
  border-bottom: 1px solid #e1e5eb;
  font-weight: 300;
  padding: 0.5em 1em;

  @media (max-width: 640px) {
    max-width: 310px;
  }

  &:first-child {
    border-top: 1px solid #e1e5eb;
  }

  .active,
  &:hover {
    background-color: #fbfbfb;
  }
`

export const DropDownSelect = ({ items, setObject, title, states }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [listView, setListView] = useState(items)

  const dropdown = useRef()

  useOnClickOutside(dropdown, () => {
    // Only if menu is open
    if (isOpen) {
      setIsOpen(!isOpen)
    }
  })

  useEffect(() => {
    setListView(items)
  }, [items])

  const handleclick = () => {
    setIsOpen(!isOpen)
  }

  const clickItem = (value) => {
    setObject(value)
    setIsOpen(!isOpen)
  }

  return (
    <StyledDRopDown ref={dropdown}>
      <StyledTitle onClick={handleclick}>{title}</StyledTitle>

      <StyledMenu open={isOpen}>
        <StyledMenuSearch>
          <PanelSearch
            items={items}
            setListView={setListView}
            states={states}
          />
          {/* <InputSearch characters={items} setListView={setListView} />
          <PanelCheckbox states={states} setListView={setListView} /> */}
        </StyledMenuSearch>
        <StyledUlMenu>
          {listView.map((item, i) => {
            return (
              <StyledLiMenuItem key={i} onClick={() => clickItem(item)}>
                {item.label}
              </StyledLiMenuItem>
            )
          })}
        </StyledUlMenu>
      </StyledMenu>
    </StyledDRopDown>
  )
}

DropDownSelect.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  setObject: PropTypes.func,
  title: PropTypes.string,
  states: PropTypes.arrayOf(PropTypes.object),
}

// filterOptions() {
//   return function (options, filter) {
//     var fuseOptions = {
//       shouldSort: true,
//       tokenize: true,
//       threshold: 0.6,
//       location: 0,
//       distance: 100,
//       maxPatternLength: 32,
//       minMatchCharLength: 1,
//       keys: [
//         "label"
//       ]
//     };
//     var fuse = new Fuse(options, fuseOptions);
//     return fuse.search(filter);
//   }
// }
// ...
// render() {
//   return (
//           <Select
//             name="form-field-name"
//             value={ this.state.localId }
//             onChange={ this.mkHandleChange(onAnswer()) }
//             filterOptions={this.filterOptions()}
//             options={ options }
//             placeholder={placeholder}
//           />
//     );
// }
// };
