import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  // CardFooter,
  // Modal,
  Notification,
} from '../../components'
import { mutationObjectComment } from '../../graphql'
import styled from 'styled-components'
import TextareaAutosize from 'react-textarea-autosize'

const StyledCardHeader = styled(CardHeader)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
`

export const CardForComments = ({ id, addinfo }) => {
  const [valueTextArea, setvalueTextArea] = useState(addinfo)

  const handleChange = (event) => {
    setvalueTextArea(event.target.value)
  }

  const handleSubmit = (value) => {
    addTodo({
      variables: {
        id: id,
        addinfo: value,
      },
    })
  }

  useEffect(() => {
    setvalueTextArea(addinfo)
  }, [addinfo])

  // mutation number phone
  const [addTodo] = useMutation(mutationObjectComment.query, {
    onCompleted: (data) => {
      return Notification({
        title: 'Изменения сохранены.',
        message: 'Запись комментария в объект.',
        type: 'info',
      })
    },
    onError: (error) => {
      return Notification({
        title: 'Ошибка добавления комментариев.',
        message: error,
        type: 'warning',
      })
    },
  })

  // removal metter
  // const [saveComment, ] =
  //   useMutation(mutationDeleteChannel.query)

  return (
    <Card>
      <StyledCardHeader className="border-bottom">
        <span>Comments</span>
        <Button onClick={() => handleSubmit('')}>Удалить комментарий</Button>
      </StyledCardHeader>
      <CardBody>
        <TextareaAutosize
          minRows={1}
          value={valueTextArea}
          onChange={handleChange}
        />
        <Button type="button" onClick={() => handleSubmit(valueTextArea)}>
          Отправить
        </Button>
      </CardBody>
    </Card>
  )
}

CardForComments.propTypes = { id: PropTypes.number, addinfo: PropTypes.string }
