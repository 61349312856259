import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../components'

export const ButtonPanelEditing = ({
  editing,
  openEdit,
  cancelEdit,
  saveEdit,
}) => {
  return !editing ? (
    <Button onClick={openEdit}>Редактировать</Button>
  ) : (
    // <Button onClick={openModalDelete}>Удалить счетчик</Button>
    <div>
      <Button onClick={cancelEdit}>Отменить</Button>
      <Button onClick={saveEdit}>Сохранить</Button>
    </div>
  )
}

ButtonPanelEditing.propTypes = {
  editing: PropTypes.bool,
  openEdit: PropTypes.func,
  cancelEdit: PropTypes.func,
  saveEdit: PropTypes.func,
}
