import React from 'react'
import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'
import styled from 'styled-components'

const StyledPaginateContainer = styled.div`
  .pagination {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;

    margin: 0;
    padding-left: 0;
    padding: 1rem;
    text-align: center;
    color: #0366d6;
  }

  .current-page {
    list-style-type: none;
    cursor: pointer;
    margin-right: 1.2rem;
    border: 1px solid #e1e5eb;
    border-radius: 12px;
    padding: 3px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
      /* 0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1); */ 0 0.25rem 0.53125rem
        rgba(90, 97, 105, 0.12);
    /* 0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1); */
  }

  .page-link {
    vertical-align: middle;
    min-width: 100%;
    max-width: 100%;
    padding: 0.9rem 1.5rem;
  }

  .active-page {
    border: 2px solid #e1e5eb;
    box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.6) inset;

    /* color: #035fc9; */
    font-weight: bold;
  }

  .disabled-link {
    color: gray;
    background-color: #5a61691a;
  }
  /* .active {
    border-color: transparent;
    background-color: #0366d6;
    color: white;
  } */
`

export const TablePaginate = ({
  pageCount,
  pageChangeHandler,
  currentPage,
}) => {
  return (
    <StyledPaginateContainer>
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        previousLabel="<"
        nextLabel=">"
        breakLabel="..."
        breakClassName="current-page"
        onPageChange={pageChangeHandler}
        containerClassName="pagination"
        activeClassName="active-page"
        pageClassName="current-page"
        pageLinkClassName="page-link"
        previousClassName="current-page"
        previousLinkClassName="page-link"
        nextClassName="current-page"
        nextLinkClassName="page-link"
        forcePage={currentPage}
        disabledClassName="disabled-link"
      />
    </StyledPaginateContainer>
  )
}

TablePaginate.propTypes = {
  pageCount: PropTypes.number,
  pageChangeHandler: PropTypes.func,
  currentPage: PropTypes.number,
}
