import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import { queryCustomers } from '../../graphql'
import { CheckBox } from '../../components'

const StyledFilterCustomer = styled.div`
  border: 1px solid black;
  border-radius: 5px;
  margin-bottom: 0.2em;
`

export const FilterCustomer = ({ setCustomerID }) => {
  const [customers, setCustomers] = useState([])

  const setCustomerIDCalB = useCallback(
    (arr) => {
      setCustomerID(arr)
    },
    [setCustomerID]
  )

  useEffect(() => {
    const arr = customers
      .filter((item) => item.checked === true)
      .map((item) => item.id)
    setCustomerIDCalB(arr)
  }, [customers, setCustomerIDCalB])

  useQuery(queryCustomers.query, {
    onCompleted: (data) => {
      const arr = data[queryCustomers.dataString].map((item) => {
        return { ...item, checked: false }
      })
      const ls = localStorage.getItem('customerIDObject')
      if (ls !== null && JSON.parse(ls).length === arr.length) {
        setCustomers(JSON.parse(ls))
      } else {
        setCustomers(arr)
      }
    },
  })

  const handleClick = ({ dataContext, checked }) => {
    const arr = customers.map((item) => {
      if (item.id === dataContext.id) item.checked = !checked
      return item
    })
    localStorage.setItem('customerIDObject', JSON.stringify(arr))
    setCustomers(arr)
  }
  return (
    <StyledFilterCustomer>
      {customers.map((item, i) => {
        return (
          <CheckBox
            key={i}
            name={item.id}
            label={item.title}
            checked={item.checked}
            click={handleClick}
            dataContext={item}
          />
        )
      })}
    </StyledFilterCustomer>
  )
}

FilterCustomer.propTypes = {
  setCustomerID: PropTypes.func,
}
