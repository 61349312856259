import React, { Suspense } from 'react'
import './App.scss'
import { ErrorBoundary } from './components'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Routes from './Routes'

const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
)

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <ReactNotification />
      <ErrorBoundary>
        <Routes />
      </ErrorBoundary>
    </Suspense>
  )
}

export default App
