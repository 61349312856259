import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, CardBody, CardHeader } from '../../components'
import { AddingMeterCard } from './AddingMeterCard'
import styled from 'styled-components'
import { EditObjectCardBody } from './EditObjectCardBody'

import { store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { translateStatusColor } from '../../utils'

const StyledCardHeader = styled(CardHeader)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`

const StyledCardHeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`

const StyledCircle = styled.div`
  display: inline-block;
  margin-right: 1em;
  background: ${(props) => props.inputColor || 'palevioletred'};
  border: 4px solid #e1e5eb;
  border-radius: 50%;
  /* box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.3); */
  height: 1.5em;
  width: 1.5em;
`

// const StyledDivFlex = styled.div`
//   display: flex;
//   flex-direction: row;
//   flex-wrap: nowrap;
//   justify-content: flex-start;
//   align-items: center;
// `

export const ObjectCard = ({
  object,
  updateDataObject,
  meterAddress,
  meters,
}) => {
  const [editer, setEditer] = useState(false)
  const [saveble, setSaveble] = useState(false) // false - cancel change, true - save change
  const [checkAddress, setCheckAddress] = useState()
  const [isOpen, setIsOpen] = useState(false)

  // check clear address
  useEffect(() => {
    if (meterAddress.length !== 2) {
      setCheckAddress(false)
      return
    }
    meterAddress.forEach((item) => {
      setCheckAddress(['20', '30'].includes(item))
    })
  }, [meterAddress])

  const enableEdit = () => {
    setEditer(true)
  }

  const cancelEditObject = () => {
    setEditer(false)
  }

  const saveEdit = () => {
    setSaveble(true)
  }

  const savedEdit = () => {
    store.addNotification({
      title: 'Изменения сохранены.',
      message: 'номер телефона',
      type: 'success',
      insert: 'top',
      container: 'top-full',
      animationIn: ['animate__animated', 'animate__zoomIn'],
      animationOut: ['animate__animated', 'animate__zoomOut'],
      dismiss: {
        duration: 2000,
        onScreen: false,
      },
    })
    updateDataObject()
    setSaveble(false)
    setEditer(false)
  }

  const openAddingMeter = () => {
    setIsOpen(true)
  }

  const closeAddingMeter = () => {
    setIsOpen(false)
  }

  // const handleCancel = () => {
  //   setIsOpen(false)
  //   setSaving(false)
  // }

  return (
    <Card>
      <StyledCardHeader className="border-bottom">
        <StyledCardHeaderLeft>
          <StyledCircle
            inputColor={translateStatusColor(object.statusid)}></StyledCircle>
          <span>
            Объект: {object.billingcode} {object.customer} {object.street}
          </span>
          {/* <span>{translateStatusid(object.statusid)}</span> */}
        </StyledCardHeaderLeft>

        {!editer ? (
          <Button onClick={enableEdit}>Редактировать</Button>
        ) : (
          <div>
            <Button onClick={cancelEditObject}>Отменить</Button>
            <Button onClick={saveEdit}>Сохранить</Button>
          </div>
        )}
      </StyledCardHeader>
      {!editer ? (
        <CardBody>
          {/* <StyledDivFlex> */}
          {/* <StyledCircle
              inputColor={translateStatusColor(object.statusid)}></StyledCircle> */}
          {/* <span>{translateStatusid(object.statusid)}</span>
          </StyledDivFlex> */}
          <p> Владелец: {object.customer}</p>
          <p>телефон: {object.phone}</p>
          <p>Адрес: {object.street}</p>
          <p>Состояние: {object.state}</p>
          <p>
            Координаты: {object.longitude}, {object.latitude}
          </p>
          {!checkAddress && (
            <Button onClick={openAddingMeter}>Добавить счетчик</Button>
          )}
        </CardBody>
      ) : (
        <EditObjectCardBody
          object={object}
          saveble={saveble}
          savedEdit={savedEdit}
          meterAddress={meterAddress}
          meters={meters}
          cancelEditObject={cancelEditObject}
        />
      )}
      {/* <Modal
        title="Добавление счетчика"
        isOpen={isOpen}
        onCancel={handleCancel}
        onSubmit={handleSubmit}> */}
      {isOpen && (
        <AddingMeterCard
          isOpen={isOpen}
          closeAddingMeter={closeAddingMeter}
          id={object.id}
          addressExists={meterAddress}
        />
      )}
      {/* </Modal> */}
    </Card>
  )
}

ObjectCard.propTypes = {
  object: PropTypes.object,
  updateDataObject: PropTypes.func,
  meterAddress: PropTypes.arrayOf(PropTypes.string),
  meters: PropTypes.arrayOf(PropTypes.object),
}
ObjectCard.defaultProps = { meterAddress: [] }
