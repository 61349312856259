import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledTitle = styled.span`
  margin-right: 1em;
`
const StyledP = styled.span`
  margin-right: 1em;
  background: ${(props) => props.background};
`

const StyledQ = styled.span`
  margin-right: 1em;
  background: ${(props) => props.background};
`
const StyledS = styled.span`
  margin-right: 1em;
  background: ${(props) => props.background};
`

export const CheckChannel = ({ channel }) => {
  const [paramChannel, setParamChannel] = useState({})

  const checkP = (p) => {
    if (p < 0) {
      return 'red'
    }
  }

  const checkQ = (q) => {
    if (q >= 0) {
      return 'red'
    }
  }

  const checkS = (s, p) => {
    if (s < p) {
      return 'red'
    }
  }

  const unphasing = (p, q, s) => {
    if (p < 0 && q >= 0 && s < p) {
      return 'Расфазировка'
    }
  }

  useEffect(() => {
    setParamChannel(channel.rs_pu_array[0])
  }, [channel])

  const total_p = paramChannel.total_p
  const total_q = paramChannel.total_q
  const total_s = paramChannel.total_s

  return (
    <div>
      <StyledTitle>{channel.channel_num}</StyledTitle>
      <StyledP background={checkP(total_p)} title="Если < 0 - красный">
        {total_p}
      </StyledP>
      <StyledQ background={checkQ(total_q)} title="Если >= 0 - красный">
        {total_q}
      </StyledQ>
      <StyledS
        background={checkS(total_s, total_p)}
        title="Если s < p - красный">
        {total_s}
      </StyledS>
      <StyledTitle>{unphasing(total_p, total_q, total_s)}</StyledTitle>
    </div>
  )
}

CheckChannel.propTypes = { channel: PropTypes.object }
