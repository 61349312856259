import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { queryCustomers } from '../../graphql'
import { useQuery } from '@apollo/client'

const StyledDiv = styled.div`
  width: 100%;
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: start;
  }
`

const StylesSelect = styled.select`
  width: 100%;
  height: calc(2.09375rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  color: #495057;
  background-size: 8px 10px;
  border: 1px solid #e1e5eb;
  border-radius: 0.25rem;
  cursor: pointer;
`

const StyledSpan = styled.span`
  margin-right: 1em;
  font-weight: bold;

  @media (max-width: 640px) {
    margin-right: 0;
    margin-bottom: 0.5em;
  }
`

export const SelectCustomer = ({ setCustomerid }) => {
  const [customers, setCustomers] = useState()
  const [customer, setCustomer] = useState()

  useQuery(queryCustomers.query, {
    onCompleted: (data) => {
      const arr = data[queryCustomers.dataString]
      setCustomers(arr)
      setCustomer(arr[0])
    },
  })

  const fetchSetCustomerid = useCallback(
    (id) => {
      setCustomerid(id)
    },
    [setCustomerid]
  )

  useEffect(() => {
    if (!customer) return
    fetchSetCustomerid(customer.id)
  }, [customer, fetchSetCustomerid])

  const onChangeSelectCustomer = (event) => {
    const v = event.target.value
    const c = customers.find((item) => item.id === +v)
    setCustomer(c)
  }

  return (
    <StyledDiv>
      <StyledSpan>Поставщик:</StyledSpan>
      {customer && (
        <StylesSelect
          type="text"
          value={customer.id}
          onChange={onChangeSelectCustomer}>
          {customers.map((item, i) => {
            return (
              <option key={i} value={item.id}>
                {item.title}
              </option>
            )
          })}
        </StylesSelect>
      )}
    </StyledDiv>
  )
}

SelectCustomer.propTypes = { setCustomerid: PropTypes.func }

// Anna
// filterOptions() {
//   return function (options, filter) {
//     var fuseOptions = {
//       shouldSort: true,
//       tokenize: true,
//       threshold: 0.6,
//       location: 0,
//       distance: 100,
//       maxPatternLength: 32,
//       minMatchCharLength: 1,
//       keys: [
//         "label"
//       ]
//     };
//     var fuse = new Fuse(options, fuseOptions);
//     return fuse.search(filter);
//   }
// }
// ...
// render() {
//   return (
//           <Select
//             name="form-field-name"
//             value={ this.state.localId }
//             onChange={ this.mkHandleChange(onAnswer()) }
//             filterOptions={this.filterOptions()}
//             options={ options }
//             placeholder={placeholder}
//           />
//     );
// }
// };
