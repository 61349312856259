import React, { useContext } from 'react'
// import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Available } from '../../components'

import { ContextUser } from '../../context'

const StyledDivSidebar = styled.div`
  height: 100%;
`

const StyledUlMenu = styled.ul`
  clear: both;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background-color: #fff;
  border-radius: 0 0 0.625rem 0.625rem;
  box-shadow: 0 0.125rem 0.625rem rgba(90, 97, 105, 0.12);
`

const StyledLiMenuItem = styled.li`
  text-decoration: none;
  display: flex;
  align-items: center;
  flex: 1 1 100%;
  width: 100%;
  white-space: nowrap;
  font-size: 0.85rem;

  &:first-child {
    border-top: 1px solid #e1e5eb;
  }

  .active,
  &:hover {
    box-shadow: inset 0.1875rem 0 0 #007bff;
    background-color: #fbfbfb;
    color: #007bff;
  }
`

const StyledLinkMenuItem = styled(NavLink)`
  color: #3d5170;
  text-decoration: none;

  white-space: nowrap;
  width: 100%;
  font-size: 1rem;

  border-bottom: 1px solid #e1e5eb;
  font-weight: 400;
  padding: 0.9375rem 1.5625rem;
`

export const Sidebar = () => {
  const { nickname } = useContext(ContextUser)
  return (
    <StyledDivSidebar>
      <StyledUlMenu>
        <Available rolesid={[1, 3]}>
          <StyledLiMenuItem>
            <StyledLinkMenuItem exact to="/">
              Главная
            </StyledLinkMenuItem>
          </StyledLiMenuItem>
        </Available>
        <Available rolesid={[1, 3]}>
          <StyledLiMenuItem>
            <StyledLinkMenuItem exact to="/objects">
              Объекты
            </StyledLinkMenuItem>
          </StyledLiMenuItem>
        </Available>
        <Available rolesid={[1]}>
          <StyledLiMenuItem>
            <StyledLinkMenuItem exact to="/meters">
              Счетчики
            </StyledLinkMenuItem>
          </StyledLiMenuItem>
        </Available>
        <Available rolesid={[1, 3]}>
          <StyledLiMenuItem>
            <StyledLinkMenuItem exact to="/new-object">
              Новый объект
            </StyledLinkMenuItem>
          </StyledLiMenuItem>
        </Available>
        <Available rolesid={[1, 2, 3]}>
          <StyledLiMenuItem>
            <StyledLinkMenuItem to={`/user/${nickname}`}>
              Личный кабинет
            </StyledLinkMenuItem>
          </StyledLiMenuItem>
        </Available>
      </StyledUlMenu>
    </StyledDivSidebar>
  )
}

// Sidebar.propTypes = {}
