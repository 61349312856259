import gql from 'graphql-tag'

export const subInventorydbMeters = {
  query: gql`
    subscription ($id: Int) {
      inventorydb_pu(
        where: { parentid: { _eq: $id } }
        order_by: { address: asc }
      ) {
        metertype
        meternum
        channels
        id
        input_ch
        installed_at
        bitrate
        address
        plomb
        sn
        parentid
      }
    }
  `,
  variables: { id: 14 },
  dataString: 'inventorydb_pu',
}

export const subInventorydbChannel = {
  query: gql`
    subscription ($id: Int) {
      inventorydb_v_channel_settings(
        where: { pu_id: { _eq: $id } }
        order_by: { id: asc }
      ) {
        id
        customercode
        customerid
        channel_num
        fpower
        inuse
        statusid
      }
    }
  `,
  variables: { id: 2 },
  dataString: 'inventorydb_v_channel_settings',
}

export const subObjectID = {
  query: gql`
    subscription ($id: Int) {
      inventorydb_v_installing_site(where: { id: { _eq: $id } }) {
        billingcode
        changed_at
        customer
        customerid
        deviceid
        id
        statusid
        phone
        inuse
        regionid
        temp_phone
        addinfo
      }
    }
  `,
  variables: { id: 1652 },
  dataString: 'inventorydb_v_installing_site',
}

/**
 * Возвращает список аварий счетчика
 *
 * @param {number} objectid - id object
 */
export const subAlertObjectID = {
  query: gql`
    subscription ($objectid: Int) {
      bigdata_alert(
        where: {
          isact: { _eq: true }
          rs_site: { id: { _eq: $objectid } }
          alerttype: { _in: [1, 2, 20, 30, 7, 8] }
        }
        order_by: { isact: desc, begintime: desc }
      ) {
        rs_alerttype {
          description
        }
      }
    }
  `,
  dataString: 'bigdata_alert',
}

/**
 * Возвращает лог объекта
 *
 * @param {jsonb} id - { "id":{ "objectid":25 }}
 */
export const subAlertObjectLog = {
  query: gql`
    subscription ($id: jsonb) {
      bigdata_userlog(
        where: { jdata: { _contains: $id } }
        order_by: { eventtime: desc }
      ) {
        eventtime
        userid
        jdata
        email
        rs_eventtype {
          description
        }
      }
    }
  `,
  dataString: 'bigdata_userlog',
}

/**return list objects from customer & region
 * @requires variables
 * @param {array} customerid - array id customers
 * @param {boolean} deviceidNull - status connect to mqtt
 * @param {[Int]} $statusid - status array monitoring
 *
 * @example variables: { regionid: [1, 2], customerid: [1, 2, 3, 4] }
 */
export const subObjectCustomer = {
  query: gql`
    subscription (
      $customerid: [Int]
      $deviceidNull: Boolean
      $statusid: [Int]
      $addinfo: Boolean
    ) {
      inventorydb_v_installing_site(
        where: {
          customerid: { _in: $customerid }
          statusid: { _in: $statusid }
        }
        order_by: { billingcode: desc }
      ) {
        billingcode
        customer
        customerid
        deviceid
        id
        statusid
        phone
        inuse
        feederid
        regionid
        temp_phone
        changed_at
        addinfo
      }
    }
  `,
  variables: {},
  dataString: 'inventorydb_v_installing_site',
}
