import gql from 'graphql-tag'

/**subs all object billing */
export const subAllObjectBilling = {
  query: gql`
    subscription QUERY_ALL_OBJECT_BILLING($token: String!) {
      meteringpoints(token: $token) {
        data {
          id
          name
          code
          longitude
          latitude
          state
          street
        }
      }
    }
  `,
  dataString: 'meteringpoints',
}
