import React, { useState } from 'react'
import { Card, CardBody, CardHeader } from '../../components'
// import styled from 'styled-components'
import { InputIdentifier } from './InputIdentifier'
import { SelectCustomer } from './SelectCustomer'
import { SelectRegion } from './SelectRegion'
import { SelectMeter } from './SelectMeter'
import { SelectPowerSystem } from './SelectPowerSystem'
import { SelectController } from './SelectController'
import { InsertNewObject } from './InsertNewObject'
import { SelectNpower } from './SelectNpower'

export const NewObject = () => {
  const [identifier, setIdentifier] = useState()
  const [customer, setCustomer] = useState()
  const [npower, setNpower] = useState()
  const [capacityah, setCapacityah] = useState()
  const [modelAKB, setModelAKB] = useState()
  const [region, setRegion] = useState()
  const [channel, setChannel] = useState()
  const [meterType, setMeterType] = useState()
  const [address, setAddress] = useState()
  const [powerSystem, setPowerSystem] = useState()
  const [controller, setController] = useState()

  return (
    <Card>
      <CardHeader borderbottom>Добавление нового объекта</CardHeader>
      <CardBody>
        <InputIdentifier setIdentifier={setIdentifier} />
        <SelectCustomer setCustomer={setCustomer} />
        <SelectRegion setRegion={setRegion} />
        <SelectNpower
          setNpower={setNpower}
          setCapacityah={setCapacityah}
          setModelAKB={setModelAKB}
        />
        <SelectMeter
          setChannel={setChannel}
          setMeterType={setMeterType}
          setAddress={setAddress}
        />
        <SelectPowerSystem setPowerSystem={setPowerSystem} />
        <SelectController setController={setController} />
        <InsertNewObject
          identifier={identifier}
          npower={npower}
          region={region}
          channel={channel}
          powerSystem={powerSystem}
          controller={controller}
          customer={customer}
          capacity={capacityah}
          modelAKB={modelAKB}
          meterType={meterType}
          address={address}
        />
      </CardBody>
    </Card>
  )
}
