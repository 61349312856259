import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { CurrentMeter } from '../../components'
// import { CurrentMeter } from './CurrentMeter'
import { useMutation } from '@apollo/client'
import { mutationDeleteMeter } from '../../graphql'
// import styled from 'styled-components'
import { store } from 'react-notifications-component'

// const StyledTD = styled.td`
//   background-color: ${(props) => props.inputColor || 'palevioletred'};
// `

export const RemovalMeterCard = ({
  meters,
  onCancel,
  removal,
  cancelEditObject,
}) => {
  const [currentMeter, setCurrentMeter] = useState()

  const fetchCancelEditObject = useCallback(() => {
    cancelEditObject()
  }, [cancelEditObject])

  const fetchOnCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  // срабатываение на нажатие кнопки сохранить или отменить
  useEffect(() => {
    if (!removal) return
    addTodo({
      variables: {
        id: currentMeter.id,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removal])

  // removal metter
  const [addTodo, { loading, error, data }] = useMutation(
    mutationDeleteMeter.query
  )

  // check result mutation
  useEffect(() => {
    if (!data) return

    if (!loading && !error) {
      store.addNotification({
        title: 'Изменения сохранены.',
        message: 'счетчик удален',
        type: 'success',
        insert: 'top',
        container: 'top-full',
        animationIn: ['animate__animated', 'animate__zoomIn'],
        animationOut: ['animate__animated', 'animate__zoomOut'],
        dismiss: {
          duration: 2000,
          onScreen: false,
        },
      })
      fetchOnCancel()
      fetchCancelEditObject()
    }
    console.log(
      'insert new meter',
      loading,

      data[mutationDeleteMeter.dataString]
    )
  }, [loading, data, error, fetchCancelEditObject, fetchOnCancel])

  const onChangeSelectMeter = (event) => {
    const v = event.target.value
    const c = meters.find((item) => item.address === v)
    setCurrentMeter(c)
  }

  useEffect(() => {
    if (!meters) return
    setCurrentMeter(meters[0])
  }, [meters])

  return currentMeter ? (
    <div>
      Выбор счетчика для удаления:
      <select
        className="btn btn-outline-secondary btn-sm"
        type="text"
        value={currentMeter.address}
        onChange={onChangeSelectMeter}>
        {meters.map((item, i) => {
          return (
            <option key={i} value={item.address}>
              {item.address}
            </option>
          )
        })}
      </select>
      <CurrentMeter meter={currentMeter} />
    </div>
  ) : (
    <></>
  )
}

RemovalMeterCard.propTypes = {
  meters: PropTypes.arrayOf(PropTypes.object),
  onCancel: PropTypes.func,
  removal: PropTypes.bool,
  cancelEditObject: PropTypes.func,
}
// RemovalMeterCard.defaultProps = { meter: {} }
