import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isEqual } from 'lodash'
// import { useQuery } from '@apollo/client'
// import { queryCustomers } from '../../graphql'
import { CheckBox } from '..'

const StyledFilterStatus = styled.div`
  border: 1px solid black;
  border-radius: 5px;
  margin-bottom: 0.2em;
`

export const FilterStatus = ({ storageKey, arrayStatus, setArrayStatus }) => {
  const [selectArray, setSelectArray] = useState([])

  const setArrayStatusC = useCallback(
    (arr) => {
      setArrayStatus(arr)
    },
    [setArrayStatus]
  )

  /**inicialice compare storage to current data */
  useEffect(() => {
    if (!storageKey) return

    const ls = localStorage.getItem(storageKey)
    if (ls === null) {
      setSelectArray(arrayStatus)
      return
    }
    const arrLs = JSON.parse(ls).map((item) => item.id)
    const arrSt = arrayStatus.map((item) => item.id)
    const lod = isEqual(arrLs, arrSt)
    if (lod) {
      setSelectArray(JSON.parse(ls))
    } else {
      localStorage.setItem(storageKey, JSON.stringify(arrayStatus))
      setSelectArray(arrayStatus)
    }
  }, [arrayStatus, storageKey])

  /**фильтр по отмеченным чекбоксам */
  useEffect(() => {
    const arr = selectArray
      .filter((item) => item.checked === true)
      .map((item) => item.id)
    setArrayStatusC(arr)
  }, [selectArray, setArrayStatusC])

  /**измененние чекбоксов */
  const handleClick = ({ dataContext, checked }) => {
    const arr = selectArray.map((item) => {
      if (item.id === dataContext.id) item.checked = !checked
      return item
    })
    localStorage.setItem(storageKey, JSON.stringify(arr))
    setSelectArray(arr)
  }

  return (
    <StyledFilterStatus>
      {selectArray.map((item, i) => {
        return (
          <CheckBox
            key={i}
            name={item.id}
            label={item.title}
            checked={item.checked}
            click={handleClick}
            dataContext={item}
          />
        )
      })}
    </StyledFilterStatus>
  )
}

FilterStatus.propTypes = {
  setCustomerID: PropTypes.func,
}
