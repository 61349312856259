import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
// import { keyBy } from 'lodash'
import styled from 'styled-components'
import { ItemCheck } from './ItemCheck'

// const StyledTitle = styled.span`
//   margin-right: 1em;
// `

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: left;
`

export const CheckSumI = ({ channels, input_ch }) => {
  const [arrCh, setArrCh] = useState()
  const [arrSum, setarrSum] = useState()

  const mChannels = useMemo(() => channels, [channels])

  useEffect(() => {
    const arr = mChannels.map((item) => {
      return { channel_num: item.channel_num, ...item.rs_pu_array[0] }
    })
    setArrCh(arr)
  }, [mChannels])

  useEffect(() => {
    const parametrs = [
      'irms_l1',
      'irms_l2',
      'irms_l3',
      'total_p',
      'total_s',
      'total_q',
    ]
    const aggParam = (parametr, arrCh) => {
      let input = 0
      let sum = 0
      arrCh.forEach((item) => {
        if (input_ch === item.channel_num) {
          input = item[parametr]
        } else {
          sum += item[parametr]
        }
      })
      return { input, sum, parametr }
    }

    if (!arrCh) return
    const arr = []
    parametrs.forEach((item) => {
      aggParam(item, arrCh)
      arr.push(aggParam(item, arrCh))
    })
    setarrSum(arr)
  }, [arrCh, input_ch])

  return (
    <StyledDiv>
      {arrSum &&
        arrSum.map((item, i) => {
          return <ItemCheck param={item} key={i} />
        })}
    </StyledDiv>
  )
}

CheckSumI.propTypes = { channels: PropTypes.number }
