import React from 'react'
import PropTypes from 'prop-types'
import { InputCheckbox } from '../../components'

export const PanelCheckbox = ({ arrCheckbox, setArrCheckbox }) => {
  const handleClick = ({ id, checked }) => {
    const arr = arrCheckbox.map((item) => {
      if (item.id === id) item.checked = !checked
      return item
    })
    setArrCheckbox(arr)
  }

  return (
    <div>
      {arrCheckbox.map((item, i) => {
        return (
          <InputCheckbox
            key={i}
            label={item.title}
            checked={item.checked}
            onChange={() => handleClick({ id: item.id, checked: item.checked })}
          />
        )
      })}
    </div>
  )
}

PanelCheckbox.propTypes = {
  arrCheckbox: PropTypes.arrayOf(PropTypes.object),
  setListView: PropTypes.func,
}
