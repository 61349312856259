import React, { useState, useEffect, useMemo, useCallback } from 'react'
// import PropTypes from 'prop-types'
import { CardBody, Button, Modal } from '../../components'
import { useMutation } from '@apollo/client'
import { mutationUpdateChannel, mutationDeleteChannel } from '../../graphql'
import { store } from 'react-notifications-component'

export const EditChannelCardBody = ({
  saving,
  channel,
  cancelEdit,
  customers,
  statusChannel,
}) => {
  const [customer, setCustomer] = useState()
  const [customercode, setCustomercode] = useState(channel.customercode)
  const [fpower, setFpower] = useState(channel.fpower)
  const [inuse, setInuse] = useState(channel.inuse)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [removal, setRemoval] = useState(false)

  const fetchCancelEdit = useCallback(() => {
    cancelEdit()
  }, [cancelEdit])

  const memoizedChannel = useMemo(() => channel, [channel])

  useEffect(() => {
    if (!customers) return
    if (!memoizedChannel.customerid) {
      setCustomer(customers[0])
      return
    }
    setCustomer(
      customers.find((item) => item.id === memoizedChannel.customerid)
    )
  }, [customers, memoizedChannel])

  // срабатываение на нажатие кнопки сохранить или отменить
  useEffect(() => {
    if (!removal) return
    addRemove({
      variables: {
        id: memoizedChannel.id,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removal])

  // removal metter
  const [addRemove, { loading: loadingRem, error: errorRem, data: dataRem }] =
    useMutation(mutationDeleteChannel.query)

  useEffect(() => {
    if (!dataRem) return

    if (!loadingRem && !errorRem) {
      store.addNotification({
        title: 'Изменения сохранены.',
        message: `канал № ${memoizedChannel.id} удален`,
        type: 'success',
        insert: 'top',
        container: 'top-full',
        animationIn: ['animate__animated', 'animate__zoomIn'],
        animationOut: ['animate__animated', 'animate__zoomOut'],
        dismiss: {
          duration: 2000,
          onScreen: false,
        },
      })
    }
  }, [loadingRem, errorRem, dataRem, memoizedChannel])

  // срабатываение на нажатие кнопки сохранить или отменить
  useEffect(() => {
    if (!saving) return
    // console.log('variables = ', {
    //   id: memoizedChannel.id,
    //   customerid: customer.id,
    //   customercode: customercode,
    //   fpower: fpower,
    //   inuse: inuse,
    //   isact: inuse,
    // })
    addTodo({
      variables: {
        id: memoizedChannel.id,
        customerid: customer.id,
        customercode: customercode,
        fpower: fpower,
        inuse: inuse,
        isact: inuse,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving])

  // mutation number phone
  const [addTodo, { loading, error, data }] = useMutation(
    mutationUpdateChannel.query
  )

  // check result mutation
  useEffect(() => {
    if (!data) return

    if (!loading && !error) {
      store.addNotification({
        title: 'Изменения сохранены.',
        message: 'параметры канала изменены',
        type: 'success',
        insert: 'top',
        container: 'top-full',
        animationIn: ['animate__animated', 'animate__zoomIn'],
        animationOut: ['animate__animated', 'animate__zoomOut'],
        dismiss: {
          duration: 2000,
          onScreen: false,
        },
      })
      fetchCancelEdit()
    }
  }, [loading, error, data, fetchCancelEdit])

  // const onChangeCustomer = (e) => {
  //   const value = e.target.value

  //   setCustomer(value)
  // }

  const openModalDelete = () => {
    setIsOpenDelete(true)
  }

  const handleCancelDelete = () => {
    setIsOpenDelete(false)
    setRemoval(false)
  }

  const handleSubmitDelete = () => {
    setIsOpenDelete(false)
    setRemoval(true)
  }

  const onChangeSelectCustomer = (event) => {
    const v = event.target.value
    const c = customers.find((item) => item.title === v)
    setCustomer(c)
  }

  const onChangeCustomercode = (e) => {
    const value = e.target.value
    setCustomercode(value)
  }

  const onChangeFpower = (e) => {
    const value = e.target.value
    setFpower(value)
  }

  const onChangeInuse = () => {
    setInuse(!inuse)
  }

  const setStatusChannel = (id) => {
    if (id === null) return
    const t = statusChannel.find((item) => {
      return item.id === id
    })
    return t.title
  }

  return (
    <CardBody>
      EditChannelCardBody{memoizedChannel.id}
      <p>
        Оператор:
        {customer && (
          <select
            type="text"
            value={customer.title}
            onChange={onChangeSelectCustomer}>
            {customers.map((item, i) => {
              return (
                <option key={i} value={item.title}>
                  {item.title}
                </option>
              )
            })}
          </select>
        )}
      </p>
      <p>
        Код:
        <input
          value={customercode}
          onChange={onChangeCustomercode}
          type="text"
        />
      </p>
      <p>
        Мощность: <input value={fpower} onChange={onChangeFpower} type="text" />
      </p>
      <p>
        <label>
          Используется:
          <input onChange={onChangeInuse} checked={inuse} type="checkbox" />
        </label>
      </p>
      <p>Статус: {setStatusChannel(memoizedChannel.statusid)}</p>
      <Button onClick={openModalDelete}>Удалить канал</Button>
      <Modal
        title="Удалить текущий канал."
        isOpen={isOpenDelete}
        onCancel={handleCancelDelete}
        onSubmit={handleSubmitDelete}
        onSubmitTitle="Удалить"
      />
    </CardBody>
  )
}

EditChannelCardBody.propTypes = {}
