import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { subObjectID, subAlertObjectID } from '../../graphql'
import { useSubscription } from '@apollo/client'
import styled from 'styled-components'

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: left;

  @media (max-width: 840px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
`

export const AlertMeter = ({ objectid }) => {
  const [object, setObject] = useState()
  const [alerts, setAlerts] = useState()

  const { data: dataObject } = useSubscription(subObjectID.query, {
    variables: { id: objectid },
  })

  useEffect(() => {
    if (!dataObject) return
    if (dataObject[subObjectID.dataString].length === 0) return
    const object = dataObject[subObjectID.dataString][0]
    setObject(object)
  }, [dataObject])

  const { data: dataAlert } = useSubscription(subAlertObjectID.query, {
    fetchPolicy: 'network-only',
    variables: { objectid: objectid },
  })

  useEffect(() => {
    if (!dataAlert) return
    // if (dataAlert[subAlertObjectID.dataString].length === 0) return
    const object = dataAlert[subAlertObjectID.dataString]
    setAlerts(object)
  }, [dataAlert])

  if (!object || (object.statusid !== 0 && object.statusid !== 1)) {
    return <div></div>
  }

  if (!alerts || alerts.length === 0) {
    return <div></div>
  }

  return (
    <StyledDiv>
      <h3>Ошибки счетчика:</h3>
      {alerts.map((item, i) => {
        return <span key={i}>{item.rs_alerttype.description}</span>
      })}
    </StyledDiv>
  )
}

AlertMeter.propTypes = { objectid: PropTypes.number }
