import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useState } from 'react'
// import { useTranslation } from 'react-i18next'
// import { useHistory } from 'react-router-dom'
import { InputPhone } from '../../components'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const StyledTable = styled.table`
  border-collapse: collapse;
  height: 100%;
  width: 100%;
`

const StyledTr = styled.tr`
  background-color: ${(props) => props.editable && '#ffcc66'};
`

const StyledTh = styled.th`
  vertical-align: middle;
  text-align: center;
  border-top: 1px solid black;
  // border-bottom: 1px solid black;
  height: 3em;
`

const StyledTd = styled.td`
  background-color: ${(props) => props.inputColor};
  vertical-align: middle;
  text-align: center;
  border-top: ${(props) => (props.editable ? '3px' : '1px')}
    ${(props) => (props.editable ? 'dashed' : 'solid')}
    ${(props) => (props.editable ? 'red' : 'black')};
  border-bottom: ${(props) => (props.editable ? '3px' : '1px')}
    ${(props) => (props.editable ? 'dashed' : 'solid')}
    ${(props) => (props.editable ? 'red' : 'black')};
  height: 3em;
`

export const TableObjectMeter = ({ data, id }) => {
  const [editable, setEditable] = useState(false)
  const [saveble, setSaveble] = useState() // false - cancel change, true - save change

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>You want to delete this file?</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                this.handleClickDelete()
                onClose()
              }}>
              Yes, Delete it!
            </button>
          </div>
        )
      },
    })
  }

  const transStatusColor = (statusid) => {
    switch (statusid) {
      case 0:
        return '#d56455c0'
      case 1:
        return '#7ec359c0'
      default:
        return '#81b0dbc0'
    }
  }

  const onClickEdit = () => {
    setEditable(true)
  }

  const onClickSave = () => {
    setSaveble(true)
    setEditable(false)
    submit()
  }

  const onClickCansel = () => {
    setSaveble(false)
    setEditable(false)
  }

  const changeSavable = (params) => {
    setSaveble(params)
  }

  return (
    <StyledTable>
      <thead>
        <StyledTr>
          <StyledTh>Тип</StyledTh>
          <StyledTh>Номер</StyledTh>
          <StyledTh>Кол-во каналов</StyledTh>
          <StyledTh>Дата уст.</StyledTh>
          <StyledTh>Cтатус</StyledTh>
          <StyledTh>Cкорость</StyledTh>
          <StyledTh>Адрес</StyledTh>
          <StyledTh>Телефон</StyledTh>
          <StyledTh></StyledTh>
        </StyledTr>
      </thead>
      <tbody>
        {data.map((item, i) => {
          return (
            <StyledTr key={i} editable={editable}>
              <StyledTd editable={editable}>{item.metertype}</StyledTd>
              <StyledTd editable={editable}>{item.meternum}</StyledTd>
              <StyledTd editable={editable}>{item.channels}</StyledTd>
              <StyledTd editable={editable}>{item.installed_at}</StyledTd>
              <StyledTd
                editable={editable}
                inputColor={transStatusColor(item.statusid)}>
                {item.statusid}
              </StyledTd>
              <StyledTd editable={editable}>{item.bitrate}</StyledTd>
              <StyledTd editable={editable}>{item.address}</StyledTd>
              <StyledTd editable={editable}>
                <InputPhone
                  number={item.phone}
                  id={id}
                  disabled={!editable}
                  saveble={saveble}
                  changeSavable={changeSavable}
                />
              </StyledTd>
              <StyledTd editable={editable}>
                {!editable ? (
                  <button onClick={() => onClickEdit()}>Ред.</button>
                ) : (
                  <div>
                    <button onClick={() => onClickSave()}>Сохранить</button>
                    <button onClick={() => onClickCansel()}>Отменить</button>
                  </div>
                )}
              </StyledTd>
            </StyledTr>
          )
        })}
      </tbody>
    </StyledTable>
  )
}

TableObjectMeter.propTypes = {
  id: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.object),
}
