import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { translateStatusColor } from '../../utils'
// import { PhoneObject } from './PhoneObject'
import { EditItemObject } from './EditItemObject'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { CopyToClipboard, Available } from '../../components'
import { Link } from 'react-router-dom'
import { StyledCopy } from '../../components/CopyToClipboard/CopyToClipboard'

const StyledObject = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  justify-content: space-evenly;
  align-content: space-evenly;
  grid-template-columns: 30px 70px 180px 100px 30px 230px 30px 200px 100px 70px;
  border-bottom: 1px solid #e1e5eb;
`

const StyledCircle = styled.div`
  display: inline-block;
  margin-right: 1em;
  background: ${(props) => props.inputColor || 'palevioletred'};
  border: 3px solid #e1e5eb;
  border-radius: 50%;
  height: 1.3em;
  width: 1.3em;
`
const StyledTitle = styled.span`
  margin-right: 0.3em;
  :hover + ${StyledCopy} {
    visibility: visible;
  }
`

const StyledItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: start;
  align-items: center;
  :hover ${StyledCopy} {
    visibility: visible;
  }
`

const StyledTitleA = styled.a`
  margin-right: 1em;
`

export const ItemObject = ({ object }) => {
  const { t } = useTranslation()
  const [editable, setEditable] = useState(false)

  if (editable) {
    return <EditItemObject object={object} setEditable={setEditable} />
  } else {
    return (
      <StyledObject>
        <StyledCircle
          inputColor={translateStatusColor(object.statusid)}></StyledCircle>
        <StyledItem>
          <StyledTitle>{object.code}</StyledTitle>
          <CopyToClipboard value={object.code} />
        </StyledItem>

        {/* <PhoneObject id={object.id} /> */}
        <StyledItem>
          <StyledTitle>{object.deviceid}</StyledTitle>
          <CopyToClipboard value={object.deviceid} />
        </StyledItem>
        <StyledItem>
          <StyledTitle>{object.phone}</StyledTitle>
          <CopyToClipboard value={object.phone} />
        </StyledItem>

        <StyledItem>
          <StyledTitle>{object.id}</StyledTitle>
          <CopyToClipboard value={object.id} />
        </StyledItem>
        <StyledTitle>
          Был в сети - {moment(object.changed_at).format('DD-MM-YY HH:mm')}
        </StyledTitle>
        <StyledTitleA
          href={`https://vpp.fescom.tech/monitoring/${object.id}/profilepower`}
          target="_blank">
          VPP
        </StyledTitleA>
        <StyledTitle>{t(`status.${object.state}`)}</StyledTitle>

        <StyledTitle>{object.addinfo}</StyledTitle>
        {/* <button type="button">Запустить быстрый опрос (з)</button> */}
        {/* <Button
          href={`https://vpp.fescom.tech/monitoring/${object.id}/profilepower`}
          type="button">
          Конфиг
        </Button> */}
        <Available rolesid={[1]}>
          <Link to={`/configure/${object.id}`}>Конфиг</Link>
        </Available>
        {/* <button type="button" onClick={() => setEditable(true)}>
          Edit (з)
        </button> */}
      </StyledObject>
    )
  }
}

ItemObject.propTypes = { object: PropTypes.object }
