import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '../../components'

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: start;
  }
`

const StyledInput = styled.input`
  width: 100%;
  min-height: 2.5em;
  padding: 0;
  /* padding: 0.4375rem 0.75rem; */
  font-size: 0.8125rem;
  /* line-height: 1.5; */
  /* color: #495057; */
  border: 1px solid #e1e5eb;
  font-weight: 300;
  will-change: border-color, box-shadow;
  border-radius: 0.25rem;
  box-shadow: none;
  margin-right: 1em;

  @media (max-width: 640px) {
    min-height: 2.4em;
    margin-right: 0;
    margin-bottom: 1em;
  }
`
const normalizeInput = (value) => {
  // const p = value.match(/^\+|\d/g)

  const vLength = value.length

  // console.log('value = ', value, value.length, p, p.length)
  // returns: +7
  if (vLength < 3) return `${value.slice(0, 2)}`

  // returns: +7 (xxx)
  if (vLength < 6) return `${value.slice(0, 2)} ${value.slice(2, 5)}`

  // returns: +7 (xxx) xxx
  if (vLength < 9)
    return `${value.slice(0, 2)} (${value.slice(2, 5)}) ${value.slice(5, 8)}`

  // returns: +7 (xxx) xxx-xx
  if (vLength < 11)
    return `${value.slice(0, 2)} (${value.slice(2, 5)}) ${value.slice(
      5,
      8
    )}-${value.slice(8, 10)}`

  // returns: +7 (xxx) xxx-xx-xx
  if (vLength < 13)
    return `${value.slice(0, 2)} (${value.slice(2, 5)}) ${value.slice(
      5,
      8
    )}-${value.slice(8, 10)}-${value.slice(10)}`
}

export const Inp = ({ phone, objecRegistered, savePhone }) => {
  const [phoneView, setPhoneView] = useState('')
  const [validPhone, setValidPhone] = useState(false)

  useEffect(() => {
    if (phoneView === '') {
      setValidPhone(false)
    } else {
      setValidPhone(true)
    }
  }, [phoneView])

  useEffect(() => {
    if (!phone) {
      setPhoneView('')
      return
    }
    setPhoneView(normalizeInput(phone))
  }, [phone])

  const onChangePhone = (e) => {
    const value = e.target.value
    const regExp = value.match(/^\+|\d/g)

    if (regExp === null) {
      setPhoneView('')
      return
    }

    // if (regExp[0] !== '+') {
    //   return
    // }
    if (regExp.length > 12) return
    setPhoneView(normalizeInput(regExp.join('')))
  }

  const fetchProduct = useCallback(
    (phone) => {
      savePhone(phone.replace(/[^+0-9]/g, ''))
    },
    [savePhone]
  )

  return (
    <StyledDiv>
      <StyledInput
        className="input"
        type="tel"
        name="phone"
        placeholder="+7 (123) 456-78-90"
        value={phoneView}
        onChange={onChangePhone}
      />
      {!objecRegistered && (
        <Button disabled={!validPhone} onClick={() => fetchProduct(phoneView)}>
          Сохранить телефон
        </Button>
      )}
    </StyledDiv>
  )
}

Inp.propTypes = {
  phone: PropTypes.string,
  objecRegistered: PropTypes.bool,
  savePhone: PropTypes.func,
}
