import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '..'

export const ResetController = ({ setResetPhone }) => {
  const ResetController = () => {
    setResetPhone(true)
  }

  return <Button onClick={ResetController}>Замена контроллера</Button>
}

ResetController.propTypes = {
  setResetPhone: PropTypes.func,
}
