import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { translateStatusid, translateStatusColor } from '../../utils'
import { subObjectID } from '../../graphql'
import { useSubscription } from '@apollo/client'
import styled from 'styled-components'
// import { store } from 'react-notifications-component'

const StyledCardHeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  @media (max-width: 840px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
`

const StyledCircle = styled.div`
  display: inline-block;
  margin-right: 1em;
  background: ${(props) => props.inputColor || 'palevioletred'};
  border: 4px solid #e1e5eb;
  border-radius: 50%;
  /* box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.3); */
  height: 1.5em;
  width: 1.5em;
`

const StyledSpan = styled.span`
  margin-right: 1em;
`

export const ObjectStatus = ({ objectid }) => {
  const [object, setObject] = useState()
  const { data: dataObject } = useSubscription(subObjectID.query, {
    variables: { id: objectid },
  })

  useEffect(() => {
    if (!dataObject) return
    if (dataObject[subObjectID.dataString].length === 0) return
    const object = dataObject[subObjectID.dataString][0]
    setObject(object)
  }, [dataObject])

  return (
    <StyledCardHeaderLeft>
      {object && (
        <StyledCircle
          inputColor={translateStatusColor(object.statusid)}></StyledCircle>
      )}
      {object && (
        <StyledSpan>
          Объект: {object.billingcode} {object.customer} {object.street}
        </StyledSpan>
      )}
      {object && <StyledSpan>{translateStatusid(object.statusid)}</StyledSpan>}
    </StyledCardHeaderLeft>
  )
}

ObjectStatus.propTypes = { objectid: PropTypes.number }
