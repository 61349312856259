import React, { useRef, useContext } from 'react'

import { HamburgerButton, SideMenu } from '../../components'
import styled from 'styled-components'
import { MenuContext } from '../../context'
import { useOnClickOutside } from '../../hooks'

const StyledHeader = styled.header`
  position: fixed;
  width: 100%;

  background-color: #fff;
  border-radius: 0 0 0.625rem 0.625rem;
  box-shadow: 0 0.125rem 0.625rem rgba(90, 97, 105, 0.12);
`
const StyledDivNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledDivBrandLogo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const StyledDivLogo = styled.div`
  margin-left: 1em;
  height: 30px;
`
const StyledDivHamb = styled.div`
  @media (min-width: 840px) {
    display: none;
  }
`

export const Topbar = () => {
  const node = useRef()
  const { isMenuOpen, toggleMenuMode } = useContext(MenuContext)
  useOnClickOutside(node, () => {
    // Only if menu is open
    if (isMenuOpen) {
      toggleMenuMode()
    }
  })

  return (
    <StyledHeader ref={node}>
      <StyledDivNavigation>
        <StyledDivBrandLogo>
          <StyledDivLogo>
            <img
              alt="FESCOM"
              src="/logo.svg"
              width="100"
              height="30"
              className="d-inline-block "
            />
          </StyledDivLogo>
        </StyledDivBrandLogo>
        <StyledDivHamb>
          <HamburgerButton />
        </StyledDivHamb>
      </StyledDivNavigation>
      <SideMenu />
    </StyledHeader>
  )
}
