import gql from 'graphql-tag'

export const queryGetTocken = {
  query: gql`
    query {
      auth(login: "odoo@fescom.tech", password: "!QAZxsw2!", db: "billing") {
        uid
        access_token
      }
    }
  `,
  variables: {},
  dataString: 'meteringpoints',
}

export const queryAllObjectBilling = {
  query: gql`
    query QUERY_ALL_OBJECT_BILLING($token: String!) {
      meteringpoints(token: $token) {
        data {
          id
          name
          code
          longitude
          latitude
          state
          street
        }
      }
    }
  `,
  variables: {},
  dataString: 'meteringpoints',
}

export const queryObjectBilling = {
  query: gql`
    query QUERY_BILLING($code: String!, $token: String!) {
      meteringpoint(code: $code, token: $token) {
        data {
          balance_foreign
          city
          code
          connection_place
          zone
          zip
          voltage_level
          tz_offset
          tz
          technical_connection_doc
          street
          state_id
          state
          reliability_category
          name
          meter_ids
          max_power
          longitude
          latitude
          id
          date
          customer
          resp_for_repair
          resp_for_equipment
          resp_for_connection
          feeding_center
          voltage
          country_id
        }
      }
    }
  `,
  variables: {},
  dataString: 'meteringpoint',
}

export const queryBillingMeter = {
  query: gql`
    query QUERY_METER($id: Int!) {
      meter(
        id: $id
        token: "access_token_b3c8098f4a95058484515cb6a0423d8312fb6861"
      ) {
        count
        data {
          type_id
          point_id
          place
          name
          id
        }
      }
    }
  `,
  variables: {},
  dataString: 'meter',
}
