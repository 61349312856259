import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { queryRegionUser } from '../../graphql'
import { ContextUser } from '../../context'
import { DropDownVariables } from './DropDownVariables'

export const SelectRegion = ({ setRegion }) => {
  const { regionid } = useContext(ContextUser)
  const [arrayElements, setArrayElements] = useState()
  const [element, setElement] = useState()

  useEffect(() => {
    if (!element) return
    setRegion(element.id)
  }, [element, setRegion])

  useQuery(queryRegionUser.query, {
    variables: { regionid },
    onCompleted: (data) => {
      setArrayElements(data[queryRegionUser.dataString])
    },
  })

  if (!arrayElements) return <></>

  return (
    <DropDownVariables
      title="Регион"
      listVar={arrayElements}
      setSelected={setElement}
    />
  )
}

SelectRegion.propTypes = { setRegion: PropTypes.func }
