import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// import Portal from '../portal/Portal'
// import Icon from '../icon/Icon'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from '../../components'
import './styles.scss'

import { useOnClickOutside } from '../../hooks'

const StyledModal = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const StyledCard = styled(Card)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between; */
  height: 50%;
  width: 50%;
  overflow: auto;
  @media (max-width: 768px) {
    height: 95%;
    width: 90%;
  }
`

const StyledCardBody = styled(CardBody)`
  /* height: 50%; */
  /* width: 50%; */
  @media (min-width: 768px) {
    /* overflow: auto; */
  }
`

const StyledCardFooter = styled(CardFooter)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const Modal = ({
  title,
  isOpen,
  onCancel,
  onSubmit,
  children,
  onSubmitTitle,
}) => {
  const modal = useRef()
  useOnClickOutside(modal, () => {
    // Only if menu is open
    if (isOpen) {
      onCancel()
    }
  })

  return (
    isOpen && (
      <StyledModal>
        <div ref={modal}>
          <StyledCard>
            <CardHeader className="border-bottom">{title}</CardHeader>
            <StyledCardBody>{children}</StyledCardBody>
            <StyledCardFooter>
              <Button onClick={onCancel}>Отмена</Button>
              <Button onClick={onSubmit}>{onSubmitTitle}</Button>
            </StyledCardFooter>
          </StyledCard>
        </div>
      </StyledModal>
    )
  )
}

Modal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
  onSubmitTitle: PropTypes.string,
}

Modal.defaultProps = {
  title: 'Modal title',
  isOpen: false,
  onCancel: () => {},
  onSubmit: () => {},
  children: null,
  onSubmitTitle: 'Сохранить',
}
