import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { queryDictController } from '../../graphql'
import { DropDownVariables } from './DropDownVariables'

export const SelectController = ({ setController }) => {
  const [arrayElements, setArrayElements] = useState()
  const [element, setElement] = useState()

  useEffect(() => {
    if (!element) return
    setController(element.title)
  }, [element, setController])

  useQuery(queryDictController.query, {
    onCompleted: (data) => {
      setArrayElements(data[queryDictController.dataString])
    },
  })

  if (!arrayElements) return <></>

  return (
    <DropDownVariables
      title="Контроллер"
      listVar={arrayElements}
      setSelected={setElement}
    />
  )
}

SelectController.propTypes = { setController: PropTypes.func }
