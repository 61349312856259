import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const StyledCopy = styled.div`
  height: 13px;
  width: 13px;
  visibility: hidden;
  &:hover {
    background-color: #e1e5eb;
  }
`

export const CopyToClipboard = ({ value }) => {
  const onClickCopy = () => {
    navigator.clipboard.writeText(value)
  }

  return (
    <StyledCopy>
      <img
        src={`/icons/svg/icon-copy.svg`}
        alt="CopyToClipboard"
        onClick={onClickCopy}
        // title="Copy"
      />
    </StyledCopy>
  )
}

CopyToClipboard.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  visible: PropTypes.bool,
}
