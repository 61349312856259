import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useBillingToken } from '../../hooks'
import { queryObjectCustomers, queryAllObjectBilling } from '../../graphql'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
// import { DropDownSelect } from '../../components'
import { useTranslation } from 'react-i18next'

import { DropDownSelect } from '../../components'

const StyledDiv = styled.div`
  width: 100%;
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: start;
  }
`

const StyledSpan = styled.span`
  margin-right: 1em;
  font-weight: bold;

  @media (max-width: 640px) {
    margin-right: 0;
    margin-bottom: 0.5em;
  }
`

export const SelectObject = ({ customerid, setObject, title }) => {
  const { t } = useTranslation()
  const [dataObjects, setDataObjects] = useState([])
  const [dataMonitoring, setDataMonitoring] = useState()
  const [states, setStates] = useState()

  // const [queryObjects] = useLazyQuery(queryObjectCustomers.query, {
  //   variables: { customerid: customerid },
  //   onCompleted: (data) => {
  //     const arr = data[queryObjectCustomers.dataString]
  //     setDataMonitoring(arr)
  //   },
  // })

  useQuery(queryObjectCustomers.query, {
    variables: { customerid: customerid },
    onCompleted: (data) => {
      const arr = data[queryObjectCustomers.dataString]
      setDataMonitoring(arr)
    },
  })

  const dataBilling = useBillingToken({
    queryBilling: queryAllObjectBilling,
  })

  useEffect(() => {
    if (!dataMonitoring || !dataBilling) return
    const B = dataBilling
    const M = dataMonitoring
    const objBill = {}
    const objMon = {}
    B.forEach((item) => (objBill[item.code] = item))
    M.forEach((item) => (objMon[item.billingcode] = item))

    // get array all join data
    const arr = M.map((item) => {
      return { ...objBill[item.billingcode], ...item }
    })

    // filter to state
    // const arr1 = arr.filter((item) => item.state === 'installation')

    // add key: value, label
    const arr2 = arr.map((item) => {
      return { value: item.id, label: `${item.code}, ${item.street}`, ...item }
    })
    setDataObjects(arr2)

    //  add list uniqque status type
    const mySetStatus = new Set()
    arr2.forEach((element) => {
      mySetStatus.add(element.state)
    })
    const arrState = Array.from(mySetStatus).map((item, i) => {
      const checked = item === 'installation' ? true : false
      return {
        level: item,
        title: t(`status.${item}`),
        checked: checked,
        id: i,
      }
    })
    setStates(arrState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMonitoring, dataBilling])

  return (
    <StyledDiv>
      <StyledSpan>Объект:</StyledSpan>
      {states && (
        <DropDownSelect
          title={title}
          items={dataObjects}
          setObject={setObject}
          states={states}
        />
      )}
    </StyledDiv>
  )
}

SelectObject.propTypes = {
  setObject: PropTypes.func,
  customerid: PropTypes.number,
}
