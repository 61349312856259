import React, { useEffect, useState } from 'react'
import { useSubscription } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { subObjectCustomer, queryAllObjectBilling } from '../../graphql'
import {
  // TableWrapper,
  Card,
  CardBody,
  InputSearch,
  FilterCustomer,
  FilterStatus,
  // FilterStatusSingle,
  // CheckBox,
} from '../../components'
import { useBillingToken } from '../../hooks'
import { ListObjects } from './ListObjects'
import { ExportXlsxObjects } from './ExportXlsxObjects'
// import { FilterStateBilling } from './FilterStateBilling'

const getPhoneOperator = (phone) => {
  if (!phone) return 'нет телефона'
  if (phone[0] === '+') {
    return phone.substr(2, 3)
  } else {
    return phone.substr(0, 3)
  }
}

export const Objects = () => {
  const { t } = useTranslation()
  const [customerID, setCustomerID] = useState([])
  const [dataTable, setdataTable] = useState()
  const [stateBilling, setStateBilling] = useState()
  const [selectStateBilling, setSelectStateBilling] = useState([])
  const [operator, setOperator] = useState()
  const [selectOperatorID, setOperatorID] = useState([])

  const [selectStatusid, setSelectStatusid] = useState([])
  const [dataFilterOperator, setDataFilterOperator] = useState()
  const [dataForSearch, setDataForSearch] = useState([])
  const [value, setValue] = useState([]) // value -changes data
  const [valueSearchDefault] = useState('')
  const [itemSearch] = useState([
    'billingcode',
    'customer',
    'street',
    'statusid',
    'phone',
    'deviceid',
    'id',
  ])

  const [statusid] = useState([
    {
      id: 0,
      level: 0,
      checked: true,
      title: 'Зарегистрирован, связь отсутствует',
    },
    {
      id: 1,
      level: 1,
      checked: true,
      title: 'Зарегистрирован, на связи',
    },
    // { id: 2, level: 2, checked: true, title: 'Привязан' },
    { id: 3, level: 3, checked: true, title: 'Не привязан' },
  ])
  // const [comments, setComments] = useState()

  /**find uniq phone */
  useEffect(() => {
    if (!dataFilterOperator) return
    if (dataFilterOperator.length === 0) return
    const SetPhone = new Set()
    dataFilterOperator.forEach((element) => {
      SetPhone.add(getPhoneOperator(element.phone))
    })

    const operP = Array.from(SetPhone).map((item, i) => {
      return {
        id: item,
        level: item,
        checked: true,
        title: item,
      }
    })
    setOperator(operP)
  }, [dataFilterOperator])

  /**filter to phone operator */
  useEffect(() => {
    if (!dataFilterOperator) return
    const arr1 = dataFilterOperator.filter((item) =>
      selectOperatorID.includes(getPhoneOperator(item.phone))
    )
    setDataForSearch(arr1)
  }, [selectOperatorID, dataFilterOperator])

  /**parsing status objects */
  useEffect(() => {
    if (!dataTable) return
    const SetState = new Set()
    // const SetStatus = new Set()

    dataTable.forEach((element) => {
      SetState.add(element.state)
      // SetStatus.add(element.statusid)
    })
    const arr1 = Array.from(SetState).map((item, i) => {
      return {
        id: item,
        level: item,
        checked: true,
        title: t(`status.${item}`),
      }
    })
    setStateBilling(arr1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTable])

  /**use filter state objects */
  useEffect(() => {
    if (!dataTable) return
    /**filter billing */
    const arr = dataTable.filter((item) =>
      selectStateBilling.includes(item.state)
    )
    setDataFilterOperator(arr)
  }, [dataTable, selectStateBilling])

  const { data: dataMonitoring } = useSubscription(subObjectCustomer.query, {
    variables: {
      customerid: customerID,
      statusid: selectStatusid,
    },
  })

  const dataBilling = useBillingToken({
    queryBilling: queryAllObjectBilling,
  })

  useEffect(() => {
    if (!dataMonitoring || !dataBilling) return
    const B = dataBilling
    const M = dataMonitoring[subObjectCustomer.dataString]
    const objBill = {}
    const objMon = {}
    B.forEach((item) => (objBill[item.code] = item))
    M.forEach((item) => (objMon[item.billingcode] = item))

    // get array all join data
    const arr = M.map((item) => {
      return { ...objBill[item.billingcode], ...item }
    })
    setdataTable(arr)
  }, [dataMonitoring, dataBilling])

  return (
    <Card>
      <CardBody>
        <FilterCustomer setCustomerID={setCustomerID} />
        {/* <FilterStatusSingle
          // storageKey="storageComments"
          objStatus={comments}
          setStatus={setComments}
          label="Наличие комментария"
        /> */}
        <FilterStatus
          storageKey="storageStatusMon"
          arrayStatus={statusid}
          setArrayStatus={setSelectStatusid}
        />
        {stateBilling && (
          <FilterStatus
            storageKey="storageStateBilling"
            arrayStatus={stateBilling}
            setArrayStatus={setSelectStateBilling}
          />
        )}
        {operator && (
          <FilterStatus
            storageKey="storagePhoneUniq"
            arrayStatus={operator}
            setArrayStatus={setOperatorID}
          />
        )}
        {/* <TableWrapper data={dataTable} /> */}
        <InputSearch
          characters={dataForSearch}
          setListView={setValue}
          valueSearchDefault={valueSearchDefault}
          itemSearch={itemSearch}
        />
        Количество - {value.length}
        <ExportXlsxObjects bodyData={value} />
        <ListObjects data={value} />
      </CardBody>
    </Card>
  )
}

Objects.propTypes = {}
