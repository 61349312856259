import React, { useState, useEffect } from 'react'
import {
  PanelInstaller,
  LogObject,
  CheckMeters,
  Available,
} from '../../components'
// import { Objects } from '../../views'

export const Home = () => {
  const [object, setObject] = useState()
  const [label, setLabel] = useState('')

  useEffect(() => {
    if (!object) {
      setLabel('')
      return
    }
    if (!object.label) return
    setLabel(object.label)
  }, [object])

  return (
    <div>
      <Available rolesid={[1, 3]}>
        <PanelInstaller object={object} setObject={setObject} title={label} />
      </Available>
      {/* <Available rolesid={[3]}>
        <Objects />
      </Available> */}
      {object && <CheckMeters objectid={object.id} />}
      {object && <LogObject objectid={object.id} />}
    </div>
  )
}
