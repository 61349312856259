import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSubscription } from '@apollo/client'
import { subAlertObjectLog } from '../../graphql'
import { Card, CardBody, CardHeader } from '../../components'
import styled from 'styled-components'
import moment from 'moment'

const StyledDiv = styled.div`
  overflow: auto;
  max-height: 300px;
`

const StyledPHead = styled.p`
  display: grid;
  justify-items: center;
  align-items: center;
  justify-content: space-evenly;
  align-content: space-evenly;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 20px;
  border-bottom: 1px solid #e1e5eb;

  @media (max-width: 840px) {
    display: none;
  }
`

const StyledP = styled.p`
  display: grid;
  justify-items: center;
  align-items: center;
  justify-content: space-evenly;
  align-content: space-evenly;
  grid-template-columns: ${(props) =>
    props.notitems ? '1fr' : 'repeat(4, 1fr)'};
  grid-auto-rows: 20px;
  border-bottom: 1px solid #e1e5eb;

  @media (max-width: 840px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
`

const StyledSpan = styled.span`
  justify-self: center;

  @media (max-width: 840px) {
    align-self: center;
  }
`

export const LogObject = ({ objectid }) => {
  const [log, setLog] = useState()
  const { data: dataLog } = useSubscription(subAlertObjectLog.query, {
    variables: { id: { objectid: objectid } },
  })

  useEffect(() => {
    if (!dataLog) return
    const object = dataLog[subAlertObjectLog.dataString]
    setLog(object)
  }, [dataLog])

  if (!log) {
    return <div></div>
  }

  return (
    <Card>
      <CardHeader>Лог действий объекта</CardHeader>
      <CardBody bordertop>
        <StyledPHead>
          <StyledSpan>Время</StyledSpan>
          <StyledSpan>Пользователь</StyledSpan>
          <StyledSpan>Телефон</StyledSpan>
          <StyledSpan>Событие</StyledSpan>
        </StyledPHead>
        <StyledDiv>
          {log.length === 0 && (
            <StyledP notitems>
              <StyledSpan>Нет записей</StyledSpan>
            </StyledP>
          )}
          {log.map((item, i) => {
            return (
              <StyledP key={i}>
                <StyledSpan>
                  {moment(item.eventtime).format('DD-MM-YY ')}
                  {moment(item.eventtime).format('HH:mm:ss')}
                </StyledSpan>
                <StyledSpan>{item.email}</StyledSpan>
                <StyledSpan>{item.jdata.phone}</StyledSpan>
                <StyledSpan>{item.rs_eventtype.description}</StyledSpan>
              </StyledP>
            )
          })}
        </StyledDiv>
      </CardBody>
    </Card>
  )
}

LogObject.propTypes = { objectid: PropTypes.number }
