import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from '../../components'
// import { CurrentMeter } from './CurrentMeter'
import { useMutation } from '@apollo/client'
import {
  queryDictMeter,
  mutationUpdateMeter,
  mutationDeleteMeter,
} from '../../graphql'
// import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import { store } from 'react-notifications-component'

// const StyledTD = styled.td`
//   background-color: ${(props) => props.inputColor || 'palevioletred'};
// `

export const EditingMeterCard = ({
  // isOpen,
  id,
  onCancel,
  addressExists,
  saving,
  savingEdit,
  // cancelEditObject,
  meter,
}) => {
  const [metertype, setMetertype] = useState()
  const [metertypeSelect, setMetertypeSelect] = useState()
  const [bitrate, setBitrate] = useState()
  const [bitrateSelect, setBitrateSelect] = useState()
  const [address, setAddress] = useState()
  const [addressSelect, setAddressSelect] = useState()
  const [meternum, setMeternum] = useState(meter.meternum)
  const [plomb, setPlomb] = useState(meter.plomb)
  const [sn, setSn] = useState(meter.sn)
  // const [installed_at, setInstalled_at] = useState(meter.installed_at)
  // const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [removal, setRemoval] = useState(false)
  const [isOpenDelete, setIsOpenDelete] = useState(false)

  // const fetchSavingEdit = useCallback(
  //   (param) => {
  //     savingEdit(param)
  //   },
  //   [savingEdit]
  // )

  const memoizedAddressExists = useMemo(() => addressExists, [addressExists])

  // срабатываение на нажатие кнопки сохранить или отменить
  useEffect(() => {
    if (!saving) return
    // console.log('variables = ', {
    //   id: id,
    //   metertype: String(metertypeSelect.metertype),
    //   channels: metertypeSelect.channels,
    //   bitrate: String(bitrateSelect.bitrate),
    //   address: String(addressSelect.address),
    //   meternum: String(meternum),
    //   plomb: String(plomb),
    //   sn: String(sn),
    // })
    addTodo({
      variables: {
        id: id,
        metertype: String(metertypeSelect.metertype),
        channels: metertypeSelect.channels,
        bitrate: String(bitrateSelect.bitrate),
        address: String(addressSelect.address),
        meternum: String(meternum),
        plomb: String(plomb),
        sn: String(sn),
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving])

  // mutation number phone
  const [addTodo, { loading, error, data }] = useMutation(
    mutationUpdateMeter.query
  )

  useEffect(() => {
    if (!error) return
    store.addNotification({
      title: 'Ошибка при добавлении счетчика.',
      message: 'error',
      type: 'danger',
      insert: 'top',
      container: 'top-full',
      animationIn: ['animate__animated', 'animate__zoomIn'],
      animationOut: ['animate__animated', 'animate__zoomOut'],
      dismiss: {
        duration: 2000,
        onScreen: false,
      },
    })
  }, [error])

  // check result mutation
  useEffect(() => {
    if (!data) return

    if (!loading && !error) {
      // store.addNotification({
      //   title: 'Изменения сохранены.',
      //   message: 'счетчик добавлен',
      //   type: 'success',
      //   insert: 'top',
      //   container: 'top-full',
      //   animationIn: ['animate__animated', 'animate__zoomIn'],
      //   animationOut: ['animate__animated', 'animate__zoomOut'],
      //   dismiss: {
      //     duration: 2000,
      //     onScreen: false,
      //   },
      // })
      // savingEdit()
      // onCancel()
    }
    console.log(
      'insert new meter',
      loading,

      data[mutationUpdateMeter.dataString]
    )
  }, [loading, data, error])

  useEffect(() => {
    if (!bitrate) return
    setBitrateSelect(bitrate[0])
  }, [bitrate])

  useEffect(() => {
    if (!metertype) return
    setMetertypeSelect(metertype[0])
  }, [metertype])

  useEffect(() => {
    if (!address) return
    setAddressSelect(address[0])
  }, [address])

  const { data: dataMeter } = useQuery(queryDictMeter.query)

  useEffect(() => {
    if (!dataMeter) return
    const arr = dataMeter[queryDictMeter.dataString]
    if (arr === 0) return
    arr.forEach((item) => {
      if (item.title === 'metertype') {
        setMetertype(item.dict)
        return
      }
      if (item.title === 'bitrate') {
        setBitrate(item.dict)
        return
      }
      if (item.title === 'address') {
        const arrD = item.dict
        const arr = arrD.filter((item) => {
          if (memoizedAddressExists.includes(item.address)) {
            return null
          } else {
            return item
          }
        })

        setAddress(arr)
        return
      }
    })
  }, [dataMeter, memoizedAddressExists])

  // useEffect(() => {
  //   if (!dataBilling) return
  //   if (dataBilling.length === 0) return
  //   const obj = { ...dataBilling[0], ...object }
  //   setObject(obj)
  // }, [dataBilling])

  const onChangeSelectType = (event) => {
    const v = event.target.value
    const c = metertype.find((item) => item.metertype === v)
    setMetertypeSelect(c)
  }

  const onChangeSelectBitrate = (event) => {
    const v = event.target.value
    const c = bitrate.find((item) => item.bitrate === +v)
    setBitrateSelect(c)
  }

  const onChangeSelectAddress = (event) => {
    const v = event.target.value
    const c = address.find((item) => item.address === v)
    setAddressSelect(c)
  }

  const onChangeMeternum = (e) => {
    const value = e.target.value
    const regExp = value.match(/\d/g)

    if (regExp === null) {
      setMeternum('')
      return
    }
    // if (regExp.length > 12) return
    setMeternum(regExp.join(''))
  }

  const onChangePlomb = (e) => {
    const value = e.target.value
    const regExp = value.match(/\d/g)
    if (regExp === null) {
      setPlomb('')
      return
    }
    // if (regExp.length > 12) return
    setPlomb(regExp.join(''))
  }

  const onChangeSN = (e) => {
    const value = e.target.value
    const regExp = value.match(/\d/g)

    if (regExp === null) {
      setSn('')
      return
    }
    // if (regExp.length > 12) return
    setSn(regExp.join(''))
  }

  // const onChangeInstalledAt = (e) => {
  //   const value = e.target.value
  //   const regExp = value.match(/\d/g)

  //   if (regExp === null) {
  //     setInstalled_at('')
  //     return
  //   }
  //   // if (regExp.length > 12) return
  //   setInstalled_at(regExp.join(''))
  // }

  // const handleCancel = () => {
  //   onCancel()
  //   savingEdit(false)
  // }

  // const handleSubmit = () => {
  //   savingEdit(true)
  // }

  const openModalDelete = () => {
    setIsOpenDelete(true)
  }

  const handleCancelDelete = () => {
    setIsOpenDelete(false)
    setRemoval(false)
  }

  const handleSubmitDelete = () => {
    setRemoval(true)
  }

  // срабатываение на нажатие кнопки сохранить или отменить
  useEffect(() => {
    if (!removal) return
    addRemove({
      variables: {
        id: id,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removal])

  // removal metter
  const [addRemove, { loading: loadingRem, error: errorRem, data: dataRem }] =
    useMutation(mutationDeleteMeter.query)

  // check result mutation
  useEffect(() => {
    if (!dataRem) return

    if (!loadingRem && !errorRem) {
      store.addNotification({
        title: 'Изменения сохранены.',
        message: 'счетчик удален',
        type: 'success',
        insert: 'top',
        container: 'top-full',
        animationIn: ['animate__animated', 'animate__zoomIn'],
        animationOut: ['animate__animated', 'animate__zoomOut'],
        dismiss: {
          duration: 2000,
          onScreen: false,
        },
      })
      handleCancelDelete()
    }
    console.log(
      'insert new meter',
      loadingRem,

      dataRem[mutationDeleteMeter.dataString]
    )
  }, [loadingRem, errorRem, dataRem])

  return (
    // <Modal
    //   title="Редактировать счетчик"
    //   isOpen={isOpen}
    //   onCancel={handleCancel}
    //   onSubmit={handleSubmit}>
    <div>
      Тип:
      {metertypeSelect && (
        <select
          type="text"
          value={metertypeSelect.metertype}
          onChange={onChangeSelectType}>
          {metertype.map((item, i) => {
            return (
              <option key={i} value={item.metertype}>
                {item.metertype}
              </option>
            )
          })}
        </select>
      )}
      {metertypeSelect && <p>Кол-во каналов*: {metertypeSelect.channels}</p>}
      Скорость*:
      {bitrateSelect && (
        <select
          type="text"
          value={bitrateSelect.bitrate}
          onChange={onChangeSelectBitrate}>
          {bitrate.map((item, i) => {
            return (
              <option key={i} value={item.bitrate}>
                {item.bitrate}
              </option>
            )
          })}
        </select>
      )}
      {addressSelect && (
        <p>
          Адрес*:
          <select
            type="text"
            value={addressSelect.address}
            onChange={onChangeSelectAddress}>
            {address.map((item, i) => {
              return (
                <option key={i} value={item.address}>
                  {item.address}
                </option>
              )
            })}
          </select>
        </p>
      )}
      <p>
        Номер:
        <input value={meternum} onChange={onChangeMeternum} type="text" />
      </p>
      <p>
        Пломба: <input value={plomb} onChange={onChangePlomb} type="text" />
      </p>
      <p>
        Серийный номер: <input value={sn} onChange={onChangeSN} type="text" />
      </p>
      {/* <p>
        Дата установки:
        <input
          value={installed_at}
          onChange={onChangeInstalledAt}
          type="text"
        />
      </p> */}
      <Button onClick={openModalDelete}>Удалить счетчик</Button>
      <Modal
        title="Удалить текущий счетчик."
        isOpen={isOpenDelete}
        onCancel={handleCancelDelete}
        onSubmit={handleSubmitDelete}
        onSubmitTitle="Удалить"></Modal>
      {/* </Modal> */}
    </div>
  )
}

EditingMeterCard.propTypes = {
  saving: PropTypes.bool,
  id: PropTypes.number,
  onCancel: PropTypes.func,
  addressExists: PropTypes.arrayOf(PropTypes.string),
  // cancelEditObject: PropTypes.func,
}
// EditingMeterCard.defaultProps = { meter: {} }
