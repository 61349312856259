import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { queryChannelsLastDataMeter } from '../../graphql'
import { Card, CardBody, CardHeader } from '..'
// import styled from 'styled-components'
// import moment from 'moment'
import { CheckChannel } from './CheckChannel'
import { CheckSumI } from './CheckSumI'
// import { mapValues, keyBy } from 'lodash'

export const CheckParametersMeter = ({ meter, input_ch }) => {
  const [channels, setChannels] = useState()

  useQuery(queryChannelsLastDataMeter.query, {
    variables: { meterid: meter.id },
    onCompleted: (data) => {
      console.log('setChannels = ', data[queryChannelsLastDataMeter.dataString])
      setChannels(data[queryChannelsLastDataMeter.dataString])
    },
  })

  return (
    <Card>
      <CardHeader>
        Счетчик: №{meter.meternum}, тип: {meter.metertype}, каналов -{' '}
        {meter.channels}
      </CardHeader>
      <CardBody bordertop>
        {channels &&
          channels.map((item, i) => {
            return <CheckChannel key={i} channel={item} />
          })}
        {channels && channels.length > 1 && (
          <CheckSumI channels={channels} input_ch={input_ch} />
        )}
      </CardBody>
    </Card>
  )
}

CheckParametersMeter.propTypes = { objectid: PropTypes.number }
