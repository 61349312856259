import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { translateStatusColor } from '../../utils'
import { PhoneObject } from './PhoneObject'

const StyledObject = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.2em;
`

const StyledCircle = styled.div`
  display: inline-block;
  margin-right: 1em;
  background: ${(props) => props.inputColor || 'palevioletred'};
  border: 3px solid #e1e5eb;
  border-radius: 50%;
  /* box-shadow: 0px 0px 10px 2px rgba(34, 60, 80, 0.3); */
  height: 1.5em;
  width: 1.5em;
`

const StyledTitle = styled.span`
  margin-right: 1em;
`

export const EditItemObject = ({ object, setEditable }) => {
  return (
    <StyledObject>
      <StyledCircle
        inputColor={translateStatusColor(object.statusid)}></StyledCircle>
      <StyledTitle>{object.code}</StyledTitle>
      <PhoneObject id={object.id} />
      <StyledTitle>{object.deviceid}</StyledTitle>

      {/* <StyledTitle>{object.phone}</StyledTitle> */}
      <button type="button">Запустить быстрый опрос (з)</button>

      <button type="button">Загрузить конфигурацию (з)</button>
      <button type="button" onClick={() => setEditable(false)}>
        Save (з)
      </button>
      <button type="button" onClick={() => setEditable(false)}>
        Cancel (з)
      </button>
    </StyledObject>
  )
}

EditItemObject.propTypes = { object: PropTypes.object }
