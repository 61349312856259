import React, { useContext } from 'react'
import styled from 'styled-components'
import { MenuContext } from '../../context'

const MenuButton = styled.button`
  display: block;
  /* transform-origin: 16px 11px; */
  /* float: left; */
  /* margin-right: 1em; */
  outline: 0;
  border: 0;
  padding: 12px 60px;
  background: none;
  box-shadow: 0 0.125rem 0.625rem rgba(90, 97, 105, 0.12);

  span {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  :focus {
    border: medium none rgb(111, 255, 176);
    box-shadow: 0 0.125rem 0.625rem rgba(90, 97, 105, 0.12);
    outline: 0;
  }

  :hover {
    span:nth-of-type(1) {
      width: 18px;
    }

    span:nth-of-type(2) {
      width: 14px;
    }

    span:nth-of-type(3) {
      width: 18px;
    }
  }

  &.active {
    span:nth-of-type(1) {
      transform: rotate(45deg) translate(3px, 5px);
      width: 18px;
    }

    span:nth-of-type(2) {
      opacity: 0;
      pointer-events: none;
    }

    span:nth-of-type(3) {
      transform: rotate(-45deg) translate(2px, -4px);
      width: 18px;
    }
  }
`

const Bar = styled.span`
  display: block;
  width: 18px;
  height: 2px;
  margin-bottom: 3px;
  background-color: #000000;
`

export const HamburgerButton = () => {
  const { isMenuOpen, toggleMenuMode } = useContext(MenuContext)

  const clickHandler = () => {
    toggleMenuMode()
  }

  return (
    <MenuButton
      className={isMenuOpen ? 'active' : ''}
      aria-label="Открыть главное меню"
      onClick={clickHandler}>
      <Bar />
      <Bar />
      <Bar />
    </MenuButton>
  )
}
