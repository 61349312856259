import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { InputPhone } from '../../components'

const StyledPhoneObject = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`

export const PhoneObject = ({ id }) => {
  return (
    <StyledPhoneObject>
      <InputPhone
        objectid={id}
        // resetPhone={resetPhone}
        // setResetPhone={setResetPhone}
      />
      {/* <ResetController /> */}
      <button type="button">Сбросить контроллер (з)</button>
    </StyledPhoneObject>
  )
}

PhoneObject.propTypes = { id: PropTypes.number }
