import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CurrentMeter,
  Modal,
  AddingChannelCard,
} from '../../components'
import { useMutation, useSubscription } from '@apollo/client'
import { store } from 'react-notifications-component'
import { mutationDeleteMeter, subInventorydbChannel } from '../../graphql'
import { EditingMeterCard } from './EditingMeterCard'
import styled from 'styled-components'

const StyledCardHeader = styled(CardHeader)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
`

export const MeterCard = ({
  meter,
  meters,
  // fSetChannels,
  // fSetAddress,
  objectID,
  regionid,
  setShowChannels,
  setCurrentMeter,
}) => {
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [isOpenAdding, setIsOpenAdding] = useState(false)
  const [removal, setRemoval] = useState(false)
  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)
  const [addressExists, setAddressExists] = useState()
  const [channelAddress] = useState([])
  const [countChannels, setChannels] = useState(0)

  // useEffect(() => {
  //   fSetChannels(countChannels)
  //   fSetAddress(meter.address)
  // }, [countChannels])

  useEffect(() => {
    if (!meters) return
    const allAddress = meters.map((item) => item.address)
    const exist = allAddress.filter((item) => {
      if (String(meter.address) === item) {
        return null
      } else {
        console.log(allAddress, item, String(meter.address))
        return item
      }
    })
    console.log(allAddress, exist)
    setAddressExists(exist)
  }, [meters, meter])

  // const openModalDelete = () => {
  //   setIsOpenDelete(true)
  // }

  const handleCancelDelete = () => {
    setIsOpenDelete(false)
    setRemoval(false)
  }

  const handleSubmitDelete = () => {
    setRemoval(true)
  }

  // срабатываение на нажатие кнопки сохранить или отменить
  useEffect(() => {
    if (!removal) return
    addTodo({
      variables: {
        id: meter.id,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removal])

  // removal metter
  const [addTodo, { loading, error, data }] = useMutation(
    mutationDeleteMeter.query
  )

  // get countChannels
  // const [getChannels, { data: dataChannels }] = useLazyQuery(
  //   subInventorydbChannel.query,
  //   { fetchPolicy: 'network-only', variables: { id: meter.id } }
  // )
  const { data: dataChannels } = useSubscription(subInventorydbChannel.query, {
    variables: { id: meter.id },
  })

  useEffect(() => {
    if (!dataChannels) return
    // if (dataChannels[subInventorydbChannel.dataString].length === 0) return
    const arr = dataChannels[subInventorydbChannel.dataString].length
    setChannels(arr)

    // set meters Address
    // const address = arr.map((item) => item.address)
    // setChannelAddress(address)
  }, [dataChannels])

  // check result mutation
  useEffect(() => {
    if (!data) return

    if (!loading && !error) {
      store.addNotification({
        title: 'Изменения сохранены.',
        message: 'счетчик удален',
        type: 'success',
        insert: 'top',
        container: 'top-full',
        animationIn: ['animate__animated', 'animate__zoomIn'],
        animationOut: ['animate__animated', 'animate__zoomOut'],
        dismiss: {
          duration: 2000,
          onScreen: false,
        },
      })
      handleCancelDelete()
    }
    console.log(
      'insert new meter',
      loading,

      data[mutationDeleteMeter.dataString]
    )
  }, [loading, data, error])

  const openEditingMeter = () => {
    setEditing(true)
  }

  const closeEditingMeter = () => {
    setEditing(false)
  }

  const cancelEditObject = () => {
    setEditing(false)
  }

  const saveEdit = () => {
    setSaving(true)
  }

  const savingEdit = () => {
    store.addNotification({
      title: 'Изменения сохранены.',
      message: 'параметры счетчика изменены',
      type: 'success',
      insert: 'top',
      container: 'top-full',
      animationIn: ['animate__animated', 'animate__zoomIn'],
      animationOut: ['animate__animated', 'animate__zoomOut'],
      dismiss: {
        duration: 2000,
        onScreen: false,
      },
    })
    // updateDataObject()
    setEditing(false)
    setSaving(false)
  }

  const showChannels = () => {
    // getChannels()
    setShowChannels(true)
    setCurrentMeter(meter)
  }

  const hiddenChannels = () => {
    setShowChannels(false)
  }

  const openAddingChannel = () => {
    // getChannels()
    setIsOpenAdding(true)
  }
  const closeAddingChannel = () => {
    setIsOpenAdding(false)
  }

  return (
    <Card>
      <StyledCardHeader className="border-bottom">
        <span>Cчетчик адрес: {meter.address}</span>
        {!editing ? (
          <Button onClick={openEditingMeter}>Редактировать</Button>
        ) : (
          // <Button onClick={openModalDelete}>Удалить счетчик</Button>
          <div>
            <Button onClick={cancelEditObject}>Отменить</Button>
            <Button onClick={saveEdit}>Сохранить</Button>
          </div>
        )}
      </StyledCardHeader>
      <CardBody className="border-bottom">
        {!editing ? (
          <CurrentMeter meter={meter} />
        ) : (
          <EditingMeterCard
            isOpen={editing}
            saving={saving}
            savingEdit={savingEdit}
            id={meter.id}
            onCancel={closeEditingMeter}
            addressExists={addressExists}
            meter={meter}
          />
        )}
        <Button onClick={openAddingChannel}>Добавить канал</Button>
        {/* <Button>Изменить параметры счетчика</Button>
        <Button>Настроить каналы на счетчике</Button> */}
      </CardBody>
      {countChannels > 0 && (
        <CardFooter>
          <Button onClick={() => showChannels(meter.id)}>
            Показать каналы
          </Button>
          <Button onClick={() => hiddenChannels(meter.id)}>
            Скрыть каналы
          </Button>
        </CardFooter>
      )}

      <Modal
        title="Удалить текущий счетчик."
        isOpen={isOpenDelete}
        onCancel={handleCancelDelete}
        onSubmit={handleSubmitDelete}
        onSubmitTitle="Удалить"></Modal>

      {isOpenAdding && (
        <AddingChannelCard
          isOpen={isOpenAdding}
          closeAddingChannel={closeAddingChannel}
          id={meter.id}
          addressExists={channelAddress}
          meterChannels={meter.countChannels}
          inputChannels={meter.input_ch}
          objectID={objectID}
          regionid={regionid}
        />
      )}

      {/* <Modal
        title="Редактировать счетчик"
        isOpen={editing}
        onCancel={handleCancel}
        onSubmit={handleSubmit}> */}

      {/* </Modal> */}
    </Card>
  )
}

MeterCard.propTypes = { meter: PropTypes.object }
// MeterCard.defaultProps = { object: {} }
