import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MenuContext } from '../../context'
import { NavLink } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import { ContextUser } from '../../context'

const StyledUlMenu = styled.ul`
  clear: both;
  /* max-height: 0px; */
  transition: max-height 0.7s linear 0s;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background-color: #fff;
  border-radius: 0 0 0.625rem 0.625rem;
  box-shadow: 0 0.125rem 0.625rem rgba(90, 97, 105, 0.12);
  max-height: ${(props) => (props.open ? '300px' : '0px')};
`

const StyledLiMenuItem = styled.li`
  text-decoration: none;
  display: flex;
  align-items: center;
  flex: 1 1 100%;
  width: 100%;
  white-space: nowrap;
  font-size: 0.85rem;

  &:first-child {
    border-top: 1px solid #e1e5eb;
  }

  .active,
  &:hover {
    box-shadow: inset 0.1875rem 0 0 #007bff;
    background-color: #fbfbfb;
    color: #007bff;
  }
`

const StyledLinkMenuItem = styled(NavLink)`
  color: #3d5170;
  text-decoration: none;

  white-space: nowrap;
  width: 100%;
  font-size: 1rem;

  border-bottom: 1px solid #e1e5eb;
  font-weight: 400;
  padding: 0.9375rem 1.5625rem;
`

export const SideMenu = () => {
  const { keycloak } = useKeycloak()
  const isLoggedIn = keycloak.authenticated
  const { isMenuOpen, toggleMenuMode } = useContext(MenuContext)

  const { nickname } = useContext(ContextUser)

  const onClickMenu = () => {
    toggleMenuMode()
  }

  if (!isLoggedIn) return <></>

  return (
    <StyledUlMenu open={isMenuOpen} onClick={onClickMenu}>
      <StyledLiMenuItem>
        <StyledLinkMenuItem exact to="/">
          Главная
        </StyledLinkMenuItem>
      </StyledLiMenuItem>
      <StyledLiMenuItem>
        <StyledLinkMenuItem to={`/user/${nickname}`}>
          Личный кабинет
        </StyledLinkMenuItem>
      </StyledLiMenuItem>
    </StyledUlMenu>
  )
}

SideMenu.propTypes = {
  children: PropTypes.node,
}
