import React from 'react'
import PropTypes from 'prop-types'

export const CurrentMeter = ({ meter }) => {
  return (
    <div>
      <p>Тип: {meter.metertype}</p>
      <p>Кол-во каналов*: {meter.channels}</p>
      <p>Скорость*: {meter.bitrate}</p>
      <p>Адрес*: {meter.address}</p>
      <p>Номер: {meter.meternum}</p>
      <p>Пломба: {meter.plomb}</p>
      <p>Серийный номер: {meter.sn}</p>
      <p>Дата установки: {meter.installed_at}</p>

      {/* <p>metertype: {meter.metertype}</p>
                <p>channels: {meter.channels}</p>
                <p>input_ch: {meter.input_ch}</p>
                <p>installed_at: {meter.installed_at}</p>
                <p>bitrate: {meter.bitrate}</p> */}
    </div>
  )
}

CurrentMeter.propTypes = {
  meter: PropTypes.object,
}
