export const translateStatusColor = (statusid) => {
  switch (statusid) {
    // not connection
    case 0:
      // return '#d56455c0'
      return '#ff0000'

    //on connection
    case 1:
      return '#00ff00'

    // not regictration
    case 2:
      return '#ffff00'

    default:
      return '#a6a6a6'
  }
}
