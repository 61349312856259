import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

export const FormConfigObject = ({ object, meter }) => {
  const { t } = useTranslation()
  const [phone, setphone] = useState(object.phone || '')

  const onChangePhone = (e) => {
    const value = e.target.value
    const regExp = value.match(/^\+|\d/g)

    if (regExp === null) {
      setphone('')
      return
    }
    if (regExp.length > 12) return
    setphone(regExp.join(''))
  }

  return (
    <StyledArticle>
      <StyledFormGroup>
        <StyledLabel>
          <StyledSpan>{t('Phone')}</StyledSpan>
          <StyledInput value={phone} onChange={onChangePhone} type="text" />
        </StyledLabel>

        <StyledRightInline className="right-inline">
          <StyledSpan>Last Name</StyledSpan>

          <StyledInput value="Strahl" readOnly />
        </StyledRightInline>
      </StyledFormGroup>

      <StyledFormGroup>
        <StyledLabel>
          <StyledSpan>Name</StyledSpan>
          <StyledInput value="Rick" readOnly />
        </StyledLabel>
        <StyledLabel>
          <StyledSpan>Name</StyledSpan>
          <StyledInput value="Rick" readOnly />
        </StyledLabel>
      </StyledFormGroup>
    </StyledArticle>
  )
}

FormConfigObject.propTypes = {
  object: PropTypes.object,
  meter: PropTypes.arrayOf(PropTypes.object),
}

const StyledArticle = styled.article`
  padding: 10px;
  @media (max-width: 768px) {
    padding: 10px 8px;
    width: 100%;
  }
`

const StyledFormGroup = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const StyledLabel = styled.label`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const StyledRightInline = styled(StyledLabel)`
  text-align: left;
  padding-left: 10px;

  @media (max-width: 768px) {
    text-align: left;
    padding-right: 0;
    padding-left: 0;
  }
`

const StyledSpan = styled.span`
  width: 125px;
  font-weight: bold;
  font-size: 1em;
`

const StyledInput = styled.input`
  flex: 1 1 auto;
  display: block;
  margin-bottom: 10px;
  /* margin-right: 8px; */
  padding: 4px;
  margin-top: -4px;

  @media (max-width: 768px) {
    margin-top: 2px;
  }
`
