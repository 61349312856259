import React from 'react'
import PropTypes from 'prop-types'
import { Button, Notification } from '../../components'
import { useMutation, useQuery } from '@apollo/client'
import {
  insertNewObject,
  insertNewMeter,
  insertNewAKB,
  insertNewACDC,
  queryObjectLastID,
} from '../../graphql'

export const InsertNewObject = ({
  identifier,
  region,
  channel,
  powerSystem,
  controller,
  customer,
  npower,
  capacity,
  modelAKB,
  address,
  meterType,
}) => {
  // const [lastObjectID, setLastObjectID] = useState()

  useQuery(queryObjectLastID.query, {
    onCompleted: (data) => {
      console.log(data)
      // setLastObjectID(data[queryObjectLastID.dataString][0].id + 1)
    },
  })

  /**insert ACDC */
  const [insertACDC] = useMutation(insertNewACDC.query, {
    onCompleted: (data) => {
      const objID = data[insertNewACDC.dataString].returning[0]
      console.log('insertNewACDC = ', objID)
    },
    onError: (error) => {
      console.error('insertNewACDC = ', error)
      return Notification({
        title: `Ошибка добавления ACDC ${powerSystem}.`,
        message: 'error',
        type: 'warning',
      })
    },
  })

  /**insert AKB */
  const [insertAKB] = useMutation(insertNewAKB.query, {
    onCompleted: (data) => {
      const objID = data[insertNewAKB.dataString].returning[0]
      console.log('insertNewAKB = ', objID)
      insertACDC({
        variables: {
          parentid: objID.parentid,
          etype: powerSystem,
          title: controller,
        },
      })
    },
    onError: (error) => {
      console.error('insertNewAKB = ', error)
      return Notification({
        title: `Ошибка добавления батареи ${powerSystem}.`,
        message: 'error',
        type: 'warning',
      })
    },
  })

  /**insert meter */
  const [insertMeter] = useMutation(insertNewMeter.query, {
    onCompleted: (data) => {
      const objID = data[insertNewMeter.dataString].returning[0]
      console.log('insertNewMeter = ', objID)
      insertAKB({
        variables: {
          parentid: objID.parentid,
          bnum: 64,
          btype: 'LiFePO4',
          capacity: capacity,
          inuse: true,
          npower: npower,
          model: modelAKB,
        },
      })
    },
    onError: (error) => {
      console.error('insertNewMeter = ', error)
      return Notification({
        title: `Ошибка добавления счетчика ${powerSystem}.`,
        message: 'error',
        type: 'warning',
      })
    },
  })

  /**insert object */
  const [insertObject] = useMutation(insertNewObject.query, {
    onCompleted: (data) => {
      const objID = data[insertNewObject.dataString].returning[0]
      console.log('insertNewObject = ', objID.id)

      insertMeter({
        variables: {
          parentid: objID.id,
          metertype: meterType,
          channels: channel,
          address: address.toString(),
          bitrate: '38400',
          input_ch: 1,
        },
      })
      Notification({
        title: `Добавлен объект ${objID.customercode}`,
        message: '',
        type: 'success',
      })
    },
    onError: (error) => {
      console.error('insertNewObject = ', error)
      return Notification({
        title: `Ошибка добавления объекта ${identifier}.`,
        message: 'error',
        type: 'warning',
      })
    },
  })

  const handleSubmit = () => {
    insertObject({
      variables: {
        customerid: customer,
        customercode: identifier,
        regionid: region,
        pointtype: 'poll',
      },
    })
  }

  return (
    <div>
      <Button type="button" onClick={() => handleSubmit()}>
        Добавить объект
      </Button>
    </div>
  )
}

InsertNewObject.propTypes = {
  identifier: PropTypes.string,
  region: PropTypes.number,
  channel: PropTypes.number,
  powerSystem: PropTypes.string,
  controller: PropTypes.string,
  customer: PropTypes.number,
}
