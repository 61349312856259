import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'

const race = [
  'Azure Female',
  'Iron Dwarf',
  'Highborn Human',
  'Lowland Human',
  'Mountain Dwarf',
  'Scythian Elf',
  'Woodland Elf',
]

const StyledDropdown = styled.div`
  /* position: relative; */
  z-index: 11;
  top: 50px;
  margin: 0 auto;
  width: 220px;
  border: 1px solid #666;
  background: #475154;
`

const StyledDropdownText = styled.div`
  color: white;
  padding: 10px 16px;
  cursor: pointer;
  height: auto;

  :hover {
    color: white;
    background: #475154;
  }

  :after {
    content: '';
    transition: all 0.3s;
    border: solid #ccc;
    border-width: 0 1px 1px 0;
    float: right;
    margin-top: 8px;
    margin-right: 6px;
    padding: 5px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    ${(props) =>
      props.open &&
      `margin-top:1px;
transform: rotate(45deg);
-webkit-transform: rotate(45deg);`}
  }
`

const StyledDropdownItems = styled.div`
  /* position: relative; */
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
  transition: max-height 0.6s ease;
  ${(props) =>
    props.open &&
    `visibility: visible;

border-top: 1px solid #666;
height: auto;
max-height: 290px;
opacity: 1;
transition: max-height 0.7s, opacity 3s, visibility 4s ease;
`}
`

const StyledDropdownItem = styled.div`
  cursor: pointer;
  padding: 10px 18px;
  :not(:last-child) {
    border-bottom: 1px solid #666;
  }
  :hover {
    color: white;
    background: #3a4143;
  }
`

export const SelectDropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [haveText, setHaveText] = useState('')

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const handleText = (ev) => {
    setHaveText(ev.currentTarget.textContent)
  }

  const itemList = (props) => {
    const list = props.map((item) => (
      <StyledDropdownItem
        open={isOpen}
        onClick={handleText}
        className="dropdown__item"
        key={item.toString()}>
        {item}
      </StyledDropdownItem>
    ))

    return (
      <StyledDropdownItems open={isOpen} className="dropdown__items">
        {' '}
        {list}{' '}
      </StyledDropdownItems>
    )
  }

  return (
    <StyledDropdown
      open={isOpen}
      className={isOpen ? 'dropdown active' : 'dropdown'}
      onClick={handleClick}>
      <StyledDropdownText open={isOpen} className="dropdown__text">
        {!haveText ? 'Select Race' : haveText}
      </StyledDropdownText>
      {itemList(race)}
    </StyledDropdown>
  )
}

SelectDropdown.propTypes = {}
