import React, { useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Fuse from 'fuse.js'

const StyledInput = styled.input`
  /* width: 100%; */
  /* height: auto; */
  padding: 0.4375rem 0.75rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #e1e5eb;
  font-weight: 300;
  will-change: border-color, box-shadow;
  border-radius: 0.25rem;
  box-shadow: none;
  margin-bottom: 1em;
`

/**
 *
 * @param {array} characters - array source  data
 * @param {func} setListView - function for return seach list
 * @param {string} valueSearchDefault - reset value to default
 *
 * @returns {array} array list
 */
export const InputSearch = ({
  characters, // array sourcedata
  setListView, // return list search
  valueSearchDefault, // reset value to default
  itemSearch,
}) => {
  const [value, setValue] = useState('')

  const placeholder = 'поиск'
  const memoizedCharacters = useMemo(() => characters, [characters])
  const fetchResults = useCallback(
    (results) => {
      setListView(results)
    },
    [setListView]
  )

  useEffect(() => {
    setValue('')
  }, [valueSearchDefault])

  /**if value === 0 return source list */
  useEffect(() => {
    if (value.length === 0) {
      fetchResults(memoizedCharacters)
      return
    }
  }, [value, memoizedCharacters, fetchResults])

  /**
   * reset value vor search when update original list
   */
  // useEffect(() => {
  //   setValue('')
  // }, [characters])

  /** search to source list*/
  useEffect(() => {
    if (value.length === 0) {
      fetchResults(memoizedCharacters)
      return
    }

    const options = {
      threshold: 0.1,
      keys: itemSearch,
    }

    const fuse = new Fuse(memoizedCharacters, options)

    const results = fuse.search(value)
    const r = results.map((item) => {
      return item.item
    })
    fetchResults(r)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, memoizedCharacters])

  const onChangeSearch = (e) => {
    const value = e.target.value
    setValue(value)
  }

  return (
    <StyledInput
      type="text"
      value={value}
      onChange={onChangeSearch}
      placeholder={placeholder}
    />
  )
}

InputSearch.propTypes = {
  characters: PropTypes.arrayOf(PropTypes.object),
  setListView: PropTypes.func,
  valueSearchDefault: PropTypes.string,
}

// filterOptions() {
//   return function (options, filter) {
//     var fuseOptions = {
//       shouldSort: true,
//       tokenize: true,
//       threshold: 0.6,
//       location: 0,
//       distance: 100,
//       maxPatternLength: 32,
//       minMatchCharLength: 1,
//       keys: [
//         "label"
//       ]
//     };
//     var fuse = new Fuse(options, fuseOptions);
//     return fuse.search(filter);
//   }
// }
// ...
// render() {
//   return (
//           <Select
//             name="form-field-name"
//             value={ this.state.localId }
//             onChange={ this.mkHandleChange(onAnswer()) }
//             filterOptions={this.filterOptions()}
//             options={ options }
//             placeholder={placeholder}
//           />
//     );
// }
// };
