import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { queryCustomerUser } from '../../graphql'
import { ContextUser } from '../../context'
import { DropDownVariables } from './DropDownVariables'

export const SelectCustomer = ({ setCustomer }) => {
  const { customerid } = useContext(ContextUser)
  const [arrayElements, setArrayElements] = useState()
  const [element, setElement] = useState()

  useEffect(() => {
    if (!element) return
    setCustomer(element.id)
  }, [element, setCustomer])

  useQuery(queryCustomerUser.query, {
    variables: { id: customerid },
    onCompleted: (data) => {
      setArrayElements(data[queryCustomerUser.dataString])
    },
  })

  if (!arrayElements) return <></>

  return (
    <DropDownVariables
      title="Поставщик"
      listVar={arrayElements}
      setSelected={setElement}
    />
  )
}

SelectCustomer.propTypes = { setCustomer: PropTypes.func }
