import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { InputSearch } from '../../components'
import { PanelCheckbox } from './PanelCheckbox'
import styled from 'styled-components'
// import Fuse from 'fuse.js'

const StyledPanelSearch = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 640px) {
    flex-direction: column;
  }
`

// const options = {
//   // isCaseSensitive: false,
//   // includeScore: false,
//   // shouldSort: true,
//   // includeMatches: false,
//   // findAllMatches: false,
//   // minMatchCharLength: 1,
//   // location: 0,
//   threshold: 0.1,
//   // distance: 100,
//   // useExtendedSearch: false,
//   // ignoreLocation: false,
//   // ignoreFieldNorm: false,
//   keys: ['label'],
// }

export const PanelSearch = ({ items, states, setListView }) => {
  const [arrStates, setArrStates] = useState(states)
  // const [modifyList, setmodifyList] = useState(items)
  const [arrWithState, setarrWithState] = useState([])
  const [valueSearchDefault, setValueSearchDefault] = useState('')

  useEffect(() => {
    setValueSearchDefault(String(new Date()))
  }, [items])

  useEffect(() => {
    const stateTrue = arrStates
      .filter((item) => item.checked === true)
      .map((item) => item.level)
    const arrWithState = items.filter((item) => stateTrue.includes(item.state))
    setarrWithState(arrWithState)
  }, [arrStates, items])

  /** */
  useEffect(() => {
    setArrStates(states)
  }, [states])

  // /**
  //  * reset value vor search when update original list
  //  */
  // useEffect(() => {
  //   setvalueSearch('')
  // }, [items])

  /** */
  // useEffect(() => {
  //   const fuse = new Fuse(arrWithState, options)

  //   const results = fuse.search(valueSearch)
  //   const r = results.map((item) => {
  //     return item.item
  //   })
  //   setListView(r)
  // }, [arrWithState, setListView, valueSearch])

  return (
    <StyledPanelSearch>
      <InputSearch
        characters={arrWithState}
        setListView={setListView}
        valueSearchDefault={valueSearchDefault}
        itemSearch={['label']}
        // setvalueSearch={setvalueSearch}
        // valueSearch={valueSearch}
      />
      <PanelCheckbox arrCheckbox={arrStates} setArrCheckbox={setArrStates} />
    </StyledPanelSearch>
  )
}

PanelSearch.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  states: PropTypes.arrayOf(PropTypes.object),
  setListView: PropTypes.func,
}
