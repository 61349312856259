import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledDiv = styled.div`
  width: 100%;
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: start;
  }
`

const StylesSelect = styled.select`
  width: 100%;
  height: calc(2.09375rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  color: #495057;
  background-size: 8px 10px;
  border: 1px solid #e1e5eb;
  border-radius: 0.25rem;
  cursor: pointer;
`

const StyledSpan = styled.span`
  margin-right: 1em;
  font-weight: bold;

  @media (max-width: 640px) {
    margin-right: 0;
    margin-bottom: 0.5em;
  }
`

/**
 * @param {string} title - title lable
 * @param {array} listVar - array source data
 * @param {func} setSelected - function for return select item
 *
 * @returns {object} select item
 */
export const DropDownVariables = ({ title, listVar, setSelected }) => {
  const [customer, setCustomer] = useState(listVar[0])

  const calbacksetSelected = useCallback(
    (element) => {
      setSelected(element)
    },
    [setSelected]
  )

  useEffect(() => {
    calbacksetSelected(customer)
  }, [customer, calbacksetSelected])

  const onChangeSelectItem = (event) => {
    const v = event.target.value
    const c = listVar.find((item) => +item.id === +v)
    setCustomer(c)
  }

  return (
    <StyledDiv>
      <StyledSpan>{title}:</StyledSpan>
      <StylesSelect
        type="text"
        value={customer.id}
        onChange={onChangeSelectItem}>
        {listVar.map((item, i) => {
          return (
            <option key={i} value={item.id}>
              {item.title}
            </option>
          )
        })}
      </StylesSelect>
    </StyledDiv>
  )
}

DropDownVariables.propTypes = {
  title: PropTypes.string.isRequired,
  listVar: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    )
  ),
  setSelected: PropTypes.func.isRequired,
}

DropDownVariables.defaultProps = {}
