import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, CardBody, Modal } from '../../components'
import { mutationInstallerPhone } from '../../graphql'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import { AddingMeterCard } from './AddingMeterCard'
import { RemovalMeterCard } from './RemovalMeterCard'

const StyledInput = styled.input``

export const EditObjectCardBody = ({
  object,
  saveble,
  savedEdit,
  meterAddress,
  meters,
  cancelEditObject,
}) => {
  const [phone, setphone] = useState(object.phone || '')
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [saving, setSaving] = useState(false)
  const [checkAddress, setCheckAddress] = useState()
  const [removal, setRemoval] = useState(false)
  // const [addressExists, setAddressExists] = useState()

  // useEffect(() => {
  //   if (!meters) return
  //   const arr = meters.map((item) => item.address)
  //   setAddressExists(arr)
  // }, [meters])

  // check clear address
  useEffect(() => {
    if (meterAddress.length !== 2) {
      setCheckAddress(false)
      return
    }
    meterAddress.forEach((item) => {
      setCheckAddress(['20', '30'].includes(item))
    })
  }, [meterAddress])

  // mutation number phone
  const [addTodo, { loading, error, data }] = useMutation(
    mutationInstallerPhone.query
  )

  // срабатываение на нажатие кнопки сохранить или отменить
  useEffect(() => {
    if (!saveble) return
    // console.log('saveble')
    addTodo({ variables: { id: object.id, phone: phone } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveble])

  // check result mutation
  useEffect(() => {
    // console.log(loading, error, data)
    if (!data) return
    if (!loading && !error) {
      savedEdit()
    }
  }, [loading, error, data, savedEdit])

  const onChangePhone = (e) => {
    const value = e.target.value
    const regExp = value.match(/^\+|\d/g)

    if (regExp === null) {
      setphone('')
      return
    }
    if (regExp.length > 12) return
    setphone(regExp.join(''))
  }

  const openModal = () => {
    setIsOpen(true)
  }

  // const openModalDelete = () => {
  //   setIsOpenDelete(true)
  // }

  const handleCancelDelete = () => {
    setIsOpenDelete(false)
    setRemoval(false)
  }

  const handleSubmitDelete = () => {
    setRemoval(true)
  }

  const handleCancel = () => {
    setIsOpen(false)
    setSaving(false)
  }

  const handleSubmit = () => {
    setSaving(true)
  }

  return (
    <CardBody>
      <p> Владелец: {object.customer}</p>
      <p>
        телефон:
        <StyledInput value={phone} onChange={onChangePhone} type="text" />
      </p>

      {/* <div>
        <Button>Добавить счетчик</Button>
        <Button>Удалить счетчик</Button>
      </div> */}
      <p>Адрес: {object.street}</p>
      <p>Состояние: {object.state}</p>
      <p>
        Координаты: {object.longitude}, {object.latitude}
      </p>

      <div>
        {!checkAddress && <Button onClick={openModal}>Добавить счетчик</Button>}
        {/* <Button onClick={openModalDelete}>Удалить счетчик</Button> */}
      </div>

      <Modal
        title="Удаление счетчика"
        isOpen={isOpenDelete}
        onCancel={handleCancelDelete}
        onSubmit={handleSubmitDelete}
        onSubmitTitle="Удалить">
        <RemovalMeterCard
          meters={meters}
          removal={removal}
          onCancel={handleCancelDelete}
          cancelEditObject={cancelEditObject}
        />
      </Modal>

      <Modal
        title="Добавление счетчика"
        isOpen={isOpen}
        onCancel={handleCancel}
        onSubmit={handleSubmit}>
        <AddingMeterCard
          saving={saving}
          id={object.id}
          onCancel={handleCancel}
          addressExists={meterAddress}
          cancelEditObject={cancelEditObject}
        />
      </Modal>
    </CardBody>
  )
}

EditObjectCardBody.propTypes = {
  object: PropTypes.object,
  // canceled: PropTypes.bool,
  saveble: PropTypes.bool,
  // id: PropTypes.number,
  meterAddress: PropTypes.arrayOf(PropTypes.string),
  meters: PropTypes.arrayOf(PropTypes.object),
  cancelEditObject: PropTypes.func,
}

EditObjectCardBody.defaultProps = { meterAddress: [] }
