import gql from 'graphql-tag'

export const queryObjectAll = {
  query: gql`
    query {
      inventorydb_v_installing_site {
        billingcode
        changed_at
        customer
        customerid
        deviceid
        id
        statusid
        phone
        inuse
        feederid
        regionid
        temp_phone
      }
    }
  `,
  variables: {},
  dataString: 'inventorydb_v_installing_site',
}

/**return list objects from customer & region
 * @requires variables
 * @param {array} customerid - array id customers
 * @param {array} regionid - array id region
 *
 * @example variables: { regionid: [1, 2], customerid: [1, 2, 3, 4] }
 */
export const queryObjectCustomer = {
  query: gql`
    query ($customerid: [Int]) {
      inventorydb_v_installing_site(
        where: { customerid: { _in: $customerid } }
      ) {
        billingcode
        changed_at
        customer
        customerid
        deviceid
        id
        statusid
        phone
        inuse
        feederid
        regionid
        temp_phone
      }
    }
  `,
  variables: {},
  dataString: 'inventorydb_v_installing_site',
}

/**
 * return list object from customer
 *
 * @requires variables
 *
 * @param {numeric} customerid - id customet
 * @example variables: { customerid: 14 }
 *
 * change where from feederid to customerid
 */
export const queryObjectCustomers = {
  query: gql`
    query ($customerid: Int) {
      inventorydb_v_installing_site(
        where: { customerid: { _eq: $customerid } }
      ) {
        billingcode
        changed_at
        customer
        customerid
        deviceid
        id
        statusid
        regionid
        phone
        inuse
        feederid
      }
    }
  `,
  variables: { customerid: 14 },
  dataString: 'inventorydb_v_installing_site',
}

/**
 * return list object from customer for testing
 *
 * @requires variables
 *
 * @param {numeric} customerid - id customet
 * @example variables: { customerid: 14 }
 */
export const queryObjectCustomersTest = {
  query: gql`
    query ($customerid: [Int]) {
      inventorydb_v_installing_site(
        where: { customerid: { _in: $customerid } }
      ) {
        billingcode
        changed_at
        customer
        customerid
        deviceid
        id
        statusid
        regionid
        phone
        inuse
        feederid
      }
    }
  `,
  variables: { customerid: 14 },
  dataString: 'inventorydb_v_installing_site',
}

export const queryObjectID = {
  query: gql`
    query ($id: Int) {
      inventorydb_v_installing_site(where: { id: { _eq: $id } }) {
        billingcode
        changed_at
        customer
        customerid
        deviceid
        id
        statusid
        phone
        inuse
        regionid
        addinfo
      }
    }
  `,
  variables: { id: 1652 },
  dataString: 'inventorydb_v_installing_site',
}

export const queryInventorydbMeter = {
  query: gql`
    query ($id: Int) {
      inventorydb_pu(where: { parentid: { _eq: $id } }) {
        metertype
        meternum
        channels
        id
        input_ch
        installed_at
        bitrate
        address
        plomb
        sn
        parentid
      }
    }
  `,
  variables: { id: 14 },
  dataString: 'inventorydb_pu',
}

export const subInventorydbMeters = {
  subscription: gql`
    subscription ($id: Int) {
      inventorydb_pu(where: { parentid: { _eq: $id } }) {
        metertype
        meternum
        channels
        id
        input_ch
        installed_at
        bitrate
        address
        plomb
        sn
        parentid
      }
    }
  `,
  variables: { id: 14 },
  dataString: 'inventorydb_pu',
}

export const subInventorydbMeter = {
  subscription: gql`
    subscription ($id: Int) {
      inventorydb_pu(where: { id: { _eq: $id } }) {
        metertype
        meternum
        channels
        id
        input_ch
        installed_at
        bitrate
        address
        plomb
        sn
        parentid
      }
    }
  `,
  variables: { id: 14 },
  dataString: 'inventorydb_pu',
}

export const queryDictMeter = {
  query: gql`
    query {
      inventorydb_idictionary {
        title
        dict
      }
    }
  `,
  variables: {},
  dataString: 'inventorydb_idictionary',
}

export const queryInventorydbChannel = {
  query: gql`
    query ($id: Int) {
      inventorydb_channel_test(
        where: { pu_id: { _eq: $id } }
        order_by: { id: asc }
      ) {
        acc_id
        address
        channel_num
        deviceid
        id
        inuse
        objectid
        pu_id
        customer
        customercode
        fpower
        inuse
      }
    }
  `,
  variables: { id: 2 },
  dataString: 'inventorydb_channel_test',
}

export const queryInventorydbCustomer = {
  query: gql`
    query {
      inventorydb_customer(order_by: { id: asc }) {
        info
        role
        title
        title_en
        id
        customercolor
      }
    }
  `,
  variables: {},
  dataString: 'inventorydb_customer',
}

/**
 * return parameters user
 *
 * @requires variables
 *
 * @param {string} email - user email
 * @example variables: { email: '' }
 */
export const queryUserData = {
  query: gql`
    query ($email: String!) {
      inventorydb_v_user_card(where: { email: { _eq: $email } }) {
        userid
        roletitle
        roleid
        roledescription
        middlename
        lastname
        isblock
        isact
        fio
        firstname
        email
        customerid
        customer
        alerttype
        objects
        regionid
      }
    }
  `,
  variables: { email: '' },
  dataString: 'inventorydb_v_user_card',
}

/**
 * return list customers
 * without sales
 *
 * where: { role: { _in: ["account", "sale"] } }
 */
export const queryCustomers = {
  query: gql`
    {
      inventorydb_customer(
        order_by: { title: asc }
        where: { role: { _in: ["account", "poll"] } }
      ) {
        customercolor
        id
        info
        role
        title
        title_en
      }
    }
  `,
  dataString: 'inventorydb_customer',
}

/**
 * return list customers from testing
 */
export const queryCustomersTest = {
  query: gql`
    {
      inventorydb_customer(where: {}, order_by: { title: asc }) {
        customercolor
        id
        info
        role
        title
        title_en
      }
    }
  `,
  dataString: 'inventorydb_customer',
}

/**return list channels from last data
 * @requires variables
 * @param {array} objectid - array id customers *
 *
 * @example variables: {  objectid: [1, 2, 3, 4] }
 */
export const queryChannelsLastData = {
  query: gql`
    query queryChannelsLastData($objectid: [Int]) {
      inventorydb_pu(where: { parentid: { _in: $objectid } }) {
        id
        parentid
        rs_channel {
          id
          parentid
          rs_pu_array(
            limit: 10
            distinct_on: [id_channel]
            order_by: { id_channel: asc, recorded_at: desc }
          ) {
            id_channel
            recorded_at
            frequency
            irms_l1
            irms_l2
            irms_l3
            quality
            timestamp
            total_ap_energy
            total_p
            total_q
            total_s
            urms_l1
            urms_l2
            urms_l3
          }
        }
      }
    }
  `,
  variables: {},
  dataString: 'inventorydb_pu',
}

/**return list channels meter from last data
 * @requires variables
 * @param {numeric} meterid -  id meter *
 *
 * @example variables: {  meterid: 1 }
 */
export const queryChannelsLastDataMeter = {
  query: gql`
    query queryChannelsLastDataMeter($meterid: Int) {
      inventorydb_channel(where: { parentid: { _eq: $meterid } }) {
        id
        parentid
        channel_num
        rs_pu_array(
          distinct_on: [id_channel]
          order_by: { id_channel: asc, recorded_at: desc }
        ) {
          id_channel
          recorded_at
          frequency
          irms_l1
          irms_l2
          irms_l3
          quality
          timestamp
          total_ap_energy
          total_p
          total_q
          total_s
          urms_l1
          urms_l2
          urms_l3
        }
      }
    }
  `,
  variables: {},
  dataString: 'inventorydb_channel',
}

/**return list region available user
 * @requires variables
 * @param {array} regionid -  id regions
 *
 * @example variables: { regionid: [10] }
 */
export const queryRegionUser = {
  query: gql`
    query queryRegionUser($regionid: [Int!]) {
      inventorydb_region(
        where: { id: { _in: $regionid } }
        order_by: { title: asc }
      ) {
        title
        id
      }
    }
  `,
  variables: { regionid: [10] },
  dataString: 'inventorydb_region',
}

/**return list address exists to meter */
export const queryDictMeterAddress = {
  query: gql`
    query queryDictMeterAddress {
      inventorydb_dict_meter(order_by: { sorted: asc }) {
        address
        channels
        metertype
        id
      }
    }
  `,
  variables: {},
  dataString: 'inventorydb_dict_meter',
}

/**return list title power system */
export const queryDictPowerSystem = {
  query: gql`
    query queryDictPowerSystem {
      inventorydb_dict_power_system(
        distinct_on: title
        order_by: { title: asc }
      ) {
        id
        title
      }
    }
  `,
  variables: {},
  dataString: 'inventorydb_dict_power_system',
}

/**return list title power system */
export const queryDictController = {
  query: gql`
    query queryDictPowerSystem {
      inventorydb_dict_controller(order_by: { title: asc }) {
        id
        title
      }
    }
  `,
  variables: {},
  dataString: 'inventorydb_dict_controller',
}

/**return list title power system */
export const queryDictBattery = {
  query: gql`
    query queryDictBattery {
      inventorydb_dict_battery(order_by: { title: asc }) {
        id
        npower
        title
        capacityah
      }
    }
  `,
  variables: {},
  dataString: 'inventorydb_dict_battery',
}

/**return list title power system */
export const queryCustomerUser = {
  query: gql`
    query queryCustomerUser($id: [Int!]) {
      inventorydb_customer(
        where: { id: { _in: $id } }
        order_by: { title: asc }
      ) {
        id
        title
      }
    }
  `,
  variables: {},
  dataString: 'inventorydb_customer',
}

/**return list title power system */
export const queryObjectLastID = {
  query: gql`
    {
      inventorydb_object(order_by: { id: desc }, limit: 1) {
        id
      }
    }
  `,
  variables: {},
  dataString: 'inventorydb_object',
}
