import React from 'react'
import PropTypes from 'prop-types'
import exceljs from 'exceljs'
import FileSaver from 'file-saver'
import moment from 'moment'
// import { useTranslation } from 'react-i18next'

export const ExportXlsxObjects = ({ bodyData, customerTitle, from, to }) => {
  // const { t } = useTranslation()

  const fileName = `Список объектов`

  /**установка границ ячейки */
  const fullBorder = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  }

  /**закрсака ячейки серым цветом */
  const fillGray = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFD3D3D3' },
  }

  // const usedChannel = (channels_used, channels) => {
  //   if (!channels_used && !channels) return '-'
  //   return `${channels_used} ${t('of')} ${channels}`
  // }

  // const checkOperators = (arr) => {
  //   if (!arr) return '-'
  //   return arr.join(', ')
  // }

  // const checkEndDate = (date) => {
  //   if (!date) return '-'
  //   return moment(date).format('DD.MM.YYYY HH:mm:ss')
  // }

  // const getDurationAlarm = (begin, end) => {
  //   end = end || moment(new Date())
  //   const b = moment(begin)
  //   const e = moment(end)
  //   const d = moment.duration(e.diff(b)) // moment.duration(new Date(end) - new Date(begin))
  //   return `${Math.trunc(d.asHours())}${t('unit.hour')} ${d.minutes()}${t(
  //     'unit.min'
  //   )} ${d.seconds()}${t('unit.sec')}`
  // }

  /**формирование файла эксель */
  const exportExcel = async (event) => {
    event.preventDefault()
    const workbook = new exceljs.Workbook()
    const worksheet = workbook.addWorksheet('Лист 1')
    worksheet.showRowColHeaders = true
    const columns = [
      { key: 'collA', width: 25 },
      { key: 'collB', width: 30 },
      { key: 'collC', width: 21 },
      { key: 'collD', width: 15 },
      { key: 'collE', width: 25 },
    ]

    worksheet.columns = columns
    worksheet.autoFilter = 'A2:E2'
    worksheet.views = [
      {
        state: 'frozen',
        xSplit: 0,
        ySplit: 2,
      },
    ]
    const row1 = worksheet.getRow(1)
    const row2 = worksheet.getRow(2)

    row1.values = [`Список объектов`]
    row1.height = 27
    worksheet.mergeCells(1, 1, 1, 5)
    worksheet.getCell('A1').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    }
    worksheet.getCell('A1').font = {
      name: 'Times New Roman',
      family: 1,
      size: 14,
      bold: true,
    }

    row2.values = ['Id Точки поставки', 'DeviceId', 'Телефон', 'Id', 'Связь']
    row2.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    }
    row2.height = 23
    row2.font = {
      name: 'Times New Roman',
      family: 1,
      size: 13,
      bold: false,
    }
    worksheet.getCell('A2').fill = fillGray
    worksheet.getCell('A2').fill = fillGray
    worksheet.getCell('B2').fill = fillGray
    worksheet.getCell('C2').fill = fillGray
    worksheet.getCell('D2').fill = fillGray
    worksheet.getCell('E2').fill = fillGray

    row2.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    }

    worksheet.getCell('A2').border = fullBorder
    worksheet.getCell('B2').border = fullBorder
    worksheet.getCell('C2').border = fullBorder
    worksheet.getCell('D2').border = fullBorder
    worksheet.getCell('E2').border = fullBorder

    // function add row value
    bodyData.forEach((item) => {
      const rowValues = worksheet.addRow()
      rowValues.getCell(1).value = item.billingcode
      rowValues.getCell(2).value = item.deviceid
      rowValues.getCell(3).value = item.phone
      rowValues.getCell(4).value = item.id
      rowValues.getCell(5).value = moment(item.changed_at).format(
        'DD.MM.YYYY HH:mm:ss'
      )

      const row = worksheet.lastRow
      row.eachCell(function (cell) {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
        cell.font = {
          name: 'Times New Roman',
          family: 1,
          size: 12,
          bold: false,
        }
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center',
          wrapText: true,
        }
      })

      rowValues.getCell(1).alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
      }
      rowValues.getCell(2).alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
      }
      rowValues.getCell(3).alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
      }
    })

    const buffer = await workbook.xlsx.writeBuffer()
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    const fileExtension = '.xlsx'

    const blob = new Blob([buffer], { type: fileType })

    FileSaver(blob, fileName + fileExtension)
  }
  return (
    <button className="btn btn-outline-secondary" onClick={exportExcel}>
      Выгрузка в XLS
    </button>
  )
}

ExportXlsxObjects.propTypes = {
  bodyData: PropTypes.arrayOf(PropTypes.object),
  customerTitle: PropTypes.arrayOf(PropTypes.string),
}
