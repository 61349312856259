import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { ContextUser } from '../../context'

export const Available = ({ children, users, rolesid }) => {
  const { userid, roleid } = useContext(ContextUser)

  const check =
    users.find((item) => userid === item) ||
    rolesid.find((item) => roleid === item)

  return check ? <>{children} </> : null
}

Available.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  users: PropTypes.arrayOf(PropTypes.number),
  rolesid: PropTypes.arrayOf(PropTypes.number),
}

Available.defaultProps = { users: [], rolesid: [] }
