import React from 'react'
import PropTypes from 'prop-types'
// import classNames from 'classnames'
// import './styles.scss'
import styled from 'styled-components'

const StyledDiv = styled.div`
  margin: 0;
  padding: 1em 1em;
  ${(props) => props.bordertop && `border-top: 1px solid #e1e5eb;`}
  ${(props) => props.borderbottom && `border-bottom: 1px solid #e1e5eb;`}

  @media (max-width: 840px) {
    margin: 0;
    padding-left: 0.5em;
    padding-top: 0;
    padding-right: 0.5em;
    padding-bottom: 0.5em;
  }
`

export const CardBody = ({ className, tag: Tag, children, ...attrs }) => {
  // const { className, tag: Tag, children, ...attrs } = props
  // const classes = classNames(className, 'card-body')

  return <StyledDiv {...attrs}>{children}</StyledDiv>
}

CardBody.propTypes = {
  /**
   * The class name.
   */
  className: PropTypes.string,
  /**
   * The children nodes.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * The component tag.
   */
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
}

CardBody.defaultProps = {
  tag: 'div',
}
