import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '..'
// import { CurrentMeter } from './CurrentMeter'
import { useMutation } from '@apollo/client'
import {
  insertNewChannel,
  queryInventorydbCustomer,
  // subInventorydbMeter,
} from '../../graphql'
// import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import { store } from 'react-notifications-component'

// const StyledTD = styled.td`
//   background-color: ${(props) => props.inputColor || 'palevioletred'};
// `

export const AddingChannelCard = ({
  // saving,
  isOpen,
  closeAddingChannel,
  id,
  addressExists,
  meterChannels,
  inputChannels,
  objectID,
  regionid,
}) => {
  const [customers, setCustomers] = useState()
  const [customerSelect, setCustomerSelect] = useState()
  const [metertype] = useState([2, 3, 4])
  const [channelNum, setChannelNum] = useState(3)
  // const [addressSelect, setAddressSelect] = useState()
  const [inuse, setInuse] = useState(false)
  const [fpower, setFpower] = useState('')
  const [customercode, setCustomercode] = useState('')
  // const [feedernum, setFeedernum] = useState('')
  const [saving, setSaving] = useState(false)
  // const [isact, setIsact] = useState('')
  const [pointtype] = useState([{ value: 'account' }, { value: 'poll' }])
  const [pointtypeSelect, setpointtypeSelect] = useState(pointtype[0])
  // const [feederid, setFeederid] = useState('')

  const fetchCloseAddingChannel = useCallback(() => {
    closeAddingChannel()
  }, [closeAddingChannel])

  // срабатываение на нажатие кнопки сохранить или отменить
  useEffect(() => {
    if (!saving) return
    console.log('saving = ', {
      customerid: customerSelect.id,
      customercode: customercode,
      feedernum: 2,
      fpower: +fpower,
      inuse: inuse,
      isact: inuse,
      pointtype: pointtypeSelect.value,
      feederid: objectID,
      regionid: regionid,
      pu_id: id,
    })
    addTodo({
      variables: {
        customerid: customerSelect.id,
        customercode: customercode,
        feedernum: 2,
        fpower: +fpower,
        inuse: inuse,
        isact: inuse,
        pointtype: pointtypeSelect.value,
        feederid: objectID,
        regionid: regionid,
        pu_id: id,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving])

  // mutation number phone
  const [addTodo, { loading, error, data }] = useMutation(
    insertNewChannel.query
  )

  useEffect(() => {
    if (!error) return
    store.addNotification({
      title: 'Ошибка при добавлении счетчика.',
      message: 'error',
      type: 'danger',
      insert: 'top',
      container: 'top-full',
      animationIn: ['animate__animated', 'animate__zoomIn'],
      animationOut: ['animate__animated', 'animate__zoomOut'],
      dismiss: {
        duration: 2000,
        onScreen: false,
      },
    })
  }, [error])

  // check result mutation
  useEffect(() => {
    if (!data) return

    if (!loading && !error) {
      store.addNotification({
        title: 'Изменения сохранены.',
        message: 'счетчик добавлен',
        type: 'success',
        insert: 'top',
        container: 'top-full',
        animationIn: ['animate__animated', 'animate__zoomIn'],
        animationOut: ['animate__animated', 'animate__zoomOut'],
        dismiss: {
          duration: 2000,
          onScreen: false,
        },
      })
      fetchCloseAddingChannel()
      // cancelEditObject()
    }
    console.log(
      'insert new meter',
      loading,

      data[insertNewChannel.dataString]
    )
  }, [loading, data, error, fetchCloseAddingChannel])

  // useEffect(() => {
  //   if (!metertype) return
  //   setChannelNum(metertype[0])
  // }, [metertype])

  const { data: dataCustomer } = useQuery(queryInventorydbCustomer.query)

  useEffect(() => {
    if (!dataCustomer) return
    const arr = dataCustomer[queryInventorydbCustomer.dataString]
    setCustomers(arr)
    setCustomerSelect(arr[0])
  }, [dataCustomer])

  const onChangeSelectType = (event) => {
    const v = event.target.value
    const c = metertype.find((item) => item === +v)
    setChannelNum(c)
  }

  const onChangeSelectPointType = (event) => {
    const v = event.target.value
    const c = pointtype.find((item) => item.value === v)
    setpointtypeSelect(c)
  }

  const onChangeSelectCustomer = (event) => {
    const v = event.target.value
    const c = customers.find((item) => item.title === v)
    setCustomerSelect(c)
  }

  const onChangeInuse = () => {
    setInuse(!inuse)
  }

  const onChangeFpower = (e) => {
    const value = e.target.value
    const regExp = value.match(/\d/g)
    if (regExp === null) {
      setFpower('')
      return
    }
    // if (regExp.length > 12) return
    setFpower(regExp.join(''))
  }

  const onChangeCustomercode = (e) => {
    const value = e.target.value
    // const regExp = value.match(/\d/g)

    if (value === null) {
      setCustomercode('')
      return
    }
    setCustomercode(value)
  }

  // const onChangeFeederid = (e) => {
  //   const value = e.target.value
  //   const regExp = value.match(/\d/g)

  //   if (regExp === null) {
  //     setFeederid('')
  //     return
  //   }
  //   // if (regExp.length > 12) return
  //   setFeederid(regExp.join(''))
  // }

  const handleCancel = () => {
    fetchCloseAddingChannel()
    setSaving(false)
  }

  const handleSubmit = () => {
    setSaving(true)
  }

  return (
    <Modal
      title="Добавление канала"
      isOpen={isOpen}
      onCancel={handleCancel}
      onSubmit={handleSubmit}>
      <div>
        Оператор*:
        {customers && (
          <select
            type="text"
            value={customerSelect.title}
            onChange={onChangeSelectCustomer}>
            {customers.map((item, i) => {
              return (
                <option key={i} value={item.title}>
                  {item.title}
                </option>
              )
            })}
          </select>
        )}
        <p>
          Код:
          <input
            value={customercode}
            onChange={onChangeCustomercode}
            type="text"
          />
        </p>
        Номер канала:
        {channelNum && (
          <select type="text" value={channelNum} onChange={onChangeSelectType}>
            {metertype.map((item, i) => {
              return (
                <option key={i} value={item}>
                  {item}
                </option>
              )
            })}
          </select>
        )}
        <p>
          Мощность:
          <input value={fpower} onChange={onChangeFpower} type="text" />
        </p>
        {/* <p>
          Используется:
          <input type="checkbox" value={inuse} onChange={onChangeInuse} />
        </p> */}
        <p>
          <label>
            {'Используется:'}
            <input onChange={onChangeInuse} checked={inuse} type="checkbox" />
          </label>
        </p>
        <p>
          <label>
            {'Состояние:'}
            <input onChange={onChangeInuse} checked={inuse} type="checkbox" />
          </label>
        </p>
        <p>
          Тип объекта:
          <select
            type="text"
            value={pointtypeSelect.value}
            onChange={onChangeSelectPointType}>
            {pointtype.map((item, i) => {
              return (
                <option key={i} value={item.value}>
                  {item.value}
                </option>
              )
            })}
          </select>
          {/* <input value={pointtype} onChange={onChangePointtype} type="text" /> */}
        </p>
        {/* <p>
          feederid:
          <input value={objectID} onChange={onChangeFeederid} type="text" />
        </p> */}
        {/* <p>
          regionid:
          <input value={regionid} onChange={onChangeRegionid} type="text" />
        </p> */}
      </div>
    </Modal>
  )
}

AddingChannelCard.propTypes = {
  // saving: PropTypes.bool,
  id: PropTypes.number,
  onCancel: PropTypes.func,
  addressExists: PropTypes.arrayOf(PropTypes.string),
  // cancelEditObject: PropTypes.func,
}
AddingChannelCard.defaultProps = { addressExists: [] }
