import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { translateStatusid, translateStatusColor } from '../../utils'

const StyledTD = styled.td`
  background-color: ${(props) => props.inputColor || 'palevioletred'};
`

const StyledTDHidden = styled.td`
  @media (max-width: 840px) {
    display: none;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const TableBasicRow = ({ row }) => {
  const { t } = useTranslation()
  let history = useHistory()

  const HandleClickRow = (id) => {
    history.push(`/configure/${id}`)
  }

  // const transStatusid = (statusid) => {
  //   switch (statusid) {
  //     case 0:
  //       return 'Зарегистрирован, связь отсутствует'

  //     case 1:
  //       return 'Зарегистрирован, на связи'

  //     case 2:
  //       return 'Не зарегистрирован'

  //     default:
  //       return 'Неизвестное состояние'
  //   }
  // }

  // const transStatusColor = (statusid) => {
  //   switch (statusid) {
  //     case 0:
  //       return '#d56455c0'

  //     case 1:
  //       return '#7ec359c0'

  //     case 2:
  //       return '#e7b95dc0'

  //     default:
  //       return '#81b0dbc0'
  //   }
  // }

  return (
    <tr onClick={() => HandleClickRow(row.id)}>
      <td className="">{row.customer}</td>
      <td className="">{row.street}</td>
      <td className=""> {t(`status.${row.state}`)}</td>
      <td className="">{row.billingcode}</td>
      <StyledTD inputColor={translateStatusColor(row.statusid)}>
        {translateStatusid(row.statusid)}
      </StyledTD>
      <StyledTDHidden>
        <StyledLink to={`/configure/${row.id}`}>
          {/* <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() => HandleClickRow(row.id)}> */}
          Настройка
          {/* </button> */}
        </StyledLink>
      </StyledTDHidden>
    </tr>
  )
}

export default TableBasicRow
