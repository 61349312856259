import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ItemObject } from './ItemObject'

const StyledListObjects = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  /* align-items: left; */

  /* @media (max-width: 840px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  } */
`

export const ListObjects = ({ data }) => {
  return (
    <StyledListObjects>
      {data.map((item, i) => {
        return <ItemObject key={i} object={item} />
      })}
    </StyledListObjects>
  )
}

ListObjects.propTypes = { data: PropTypes.arrayOf(PropTypes.object) }
ListObjects.defaultTypes = { data: [] }
