import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledTitle = styled.span`
  margin-right: 1em;
`

// const StyledDiv = styled.div`
//   display: flex;
//   flex-direction: column;
//   flex-wrap: nowrap;
//   align-items: left;
// `

export const ItemCheck = ({ param }) => {
  return (
    <div>
      <StyledTitle>{param.parametr}</StyledTitle>
      <StyledTitle>{param.input}</StyledTitle>
      <StyledTitle>{param.sum}</StyledTitle>
    </div>
  )
}

ItemCheck.propTypes = { param: PropTypes.object }
