import React from 'react'
import PropTypes from 'prop-types'
// import classNames from 'classnames'
import './styles.scss'
import styled from 'styled-components'

const StyledCardHeader = styled.div`
  /* margin: 0;
  padding: 1em 1em; */

  padding: 1.09375rem 1.875rem;
  background-color: #fff;
  border-bottom: none;
  border-radius: 0.625rem 0.625rem 0 0;
  ${(props) => props.bordertop && `border-top: 1px solid #e1e5eb;`}
  ${(props) => props.borderbottom && `border-bottom: 1px solid #e1e5eb;`}

  @media (max-width: 840px) {
    margin: 0;
    padding-left: 0.5em;
    padding-top: 0;
    padding-right: 0.5em;
    padding-bottom: 0.5em;
  }
`

export const CardHeader = ({ className, tag: Tag, ...attrs }) => {
  // const classes = classNames(className, 'card-header')

  return <StyledCardHeader {...attrs} />
}

CardHeader.propTypes = {
  /**
   * The class name.
   */
  className: PropTypes.string,
  /**
   * The component tag type.
   */
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
}

CardHeader.defaultProps = {
  tag: 'div',
}
