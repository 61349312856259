import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { WebSocketLink } from 'apollo-link-ws'
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import './i18n'

const browserHistory = createBrowserHistory()

// Create a WebSocket link:
const link = new WebSocketLink({
  uri: 'wss://hasura3.fescom.tech/v1/graphql',
  options: {
    reconnect: true,
    connectionParams: {
      headers: {
        'x-hasura-admin-secret': 'ctrhtnysqrk.x2023',
      },
    },
  },
})
const cache = new InMemoryCache()
const client = new ApolloClient({
  link,
  cache,
})

ReactDOM.render(
  <React.StrictMode>
    <Router history={browserHistory}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
