import gql from 'graphql-tag'

export const mutationDBNewMeter = {
  query: gql`
    mutation (
      $id_object: Int
      $metertype: String
      $meternum: String
      $channels: numeric
      $bitrate: String
      $address: String
      $plomb: String
      $sn: String
    ) {
      insert_inventorydb_pu(
        objects: {
          parentid: $id_object
          metertype: $metertype
          meternum: $meternum
          installed_at: "now()"
          input_ch: 1
          channels: $channels
          bitrate: $bitrate
          address: $address
          plomb: $plomb
          sn: $sn
        }
      ) {
        affected_rows
      }
    }
  `,
  variables: {
    id_object: 8,
    address: '20',
    bitrate: 115200,
    channels: 1,
    id: 8,
    installed_at: '123',
    meternum: '123',
    metertype: 'WB-MAP3E',
    plomb: '123',
    sn: '123',
  },
  dataString: 'insert_inventorydb_pu',
}

export const mutationDeleteMeter = {
  query: gql`
    mutation delete_inventorydb_pu($id: Int) {
      delete_inventorydb_pu(where: { id: { _eq: $id } }) {
        affected_rows
      }
    }
  `,
  variables: { id: 1596 },
  dataString: 'delete_inventorydb_pu',
}

export const mutationUpdateMeter = {
  query: gql`
    mutation (
      $id: Int
      $metertype: String
      $meternum: String
      $channels: numeric
      $bitrate: String
      $address: String
      $plomb: String
      $sn: String
    ) {
      update_inventorydb_pu(
        _set: {
          metertype: $metertype
          meternum: $meternum
          installed_at: "now()"
          input_ch: 1
          channels: $channels
          bitrate: $bitrate
          address: $address
          plomb: $plomb
          sn: $sn
        }
        where: { id: { _eq: $id } }
      ) {
        affected_rows
      }
    }
  `,
  variables: {
    id: 31,
    address: '30',
    bitrate: '115200',
    channels: 1,
    meternum: '123',
    metertype: 'WB-MAP3E',
    plomb: '123',
    sn: '123',
  },
  dataString: 'update_inventorydb_pu',
}

export const insertNewChannel = {
  query: gql`
    mutation (
      $customerid: Int
      $customercode: String
      $feedernum: Int
      $fpower: numeric
      $inuse: Boolean
      $isact: Boolean
      $pointtype: String
      $feederid: Int
      $regionid: Int
      $pu_id: Int
    ) {
      insert_inventorydb_object(
        objects: {
          customerid: $customerid
          customercode: $customercode
          feedernum: $feedernum
          fpower: $fpower
          inuse: $inuse
          isact: $isact
          pointtype: $pointtype
          feederid: $feederid
          regionid: $regionid
          pu_id: $pu_id
        }
      ) {
        affected_rows
      }
    }
  `,
  variables: {
    customerid: 1,
    customercode: 'ewqewqw',
    feedernum: 2,
    fpower: 123,
    inuse: true,
    isact: true,
    pointtype: 'xcxzcz',
    feederid: 321,
    regionid: 1,
  },
  dataString: 'insert_inventorydb_object',
}

export const mutationUpdateChannel = {
  query: gql`
    mutation (
      $id: Int
      $customerid: Int
      $customercode: String
      $feedernum: Int
      $fpower: numeric
      $inuse: Boolean
      $isact: Boolean
    ) {
      update_inventorydb_object(
        _set: {
          customerid: $customerid
          customercode: $customercode
          feedernum: $feedernum
          fpower: $fpower
          inuse: $inuse
          isact: $isact
        }
        where: { id: { _eq: $id } }
      ) {
        affected_rows
      }
    }
  `,
  variables: {
    id: 5,
    customerid: 1,
    customercode: '1111111',
    feedernum: 2,
    fpower: 123,
    inuse: true,
    isact: true,
  },
  dataString: 'update_inventorydb_object',
}

export const mutationDeleteChannel = {
  query: gql`
    mutation delete_inventorydb_object($id: Int) {
      delete_inventorydb_object(where: { id: { _eq: $id } }) {
        affected_rows
      }
    }
  `,
  variables: { id: 5 },
  dataString: 'delete_inventorydb_object',
}

export const mutationInstallerPhone = {
  query: gql`
    mutation ($email: String, $userid: Int, $jdata: jsonb, $eventtype: Int) {
      insert_bigdata_userlog(
        objects: {
          email: $email
          eventtype: $eventtype
          userid: $userid
          jdata: $jdata
        }
      ) {
        affected_rows
      }
    }
  `,
  variables: {
    email: 'ewqeqweqweq',
    userid: 1,
    jdata: '{phone:123}',
    eventtype: 200,
  },
  dataString: 'insert_bigdata_userlog',
}

export const mutationObjectPhone = {
  query: gql`
    mutation ($id: Int, $phone: String) {
      insert_inventorydb_modem(
        objects: { parentid: $id, phone: $phone }
        on_conflict: { constraint: modem_parentid_key, update_columns: phone }
      ) {
        affected_rows
      }
    }
  `,
  variables: { id: 1596, phone: '37253204271_' },
  dataString: 'insert_inventorydb_modem',
}

/**
 * change addinfo on object
 *
 * @requires variables
 *
 * @param {numeric} id - id object
 * @example variables: { id: 25 }
 */
export const mutationObjectComment = {
  query: gql`
    mutation mutationObjectComment($id: Int, $addinfo: String) {
      update_inventorydb_object(
        where: { id: { _eq: $id } }
        _set: { addinfo: $addinfo }
      ) {
        affected_rows
      }
    }
  `,
  variables: { customerid: 14 },
  dataString: 'update_inventorydb_object',
}

/** set new object */
export const insertNewObject = {
  query: gql`
    mutation insertNewObject(
      $customerid: Int
      $customercode: String
      $regionid: Int
      $pointtype: String
    ) {
      insert_inventorydb_object(
        objects: {
          customerid: $customerid
          customercode: $customercode
          regionid: $regionid
          pointtype: $pointtype
        }
      ) {
        returning {
          id
          customercode
        }
      }
    }
  `,
  variables: {
    id: 99000,
    customerid: 1,
    customercode: 'test',
    regionid: 1,
    pointtype: 'poll',
  },
  dataString: 'insert_inventorydb_object',
}

/** set new meter */
export const insertNewMeter = {
  query: gql`
    mutation insertNewMeter(
      $address: String
      $bitrate: String
      $channels: numeric
      $input_ch: Int
      $metertype: String
      $parentid: Int
    ) {
      insert_inventorydb_pu(
        objects: {
          address: $address
          bitrate: $bitrate
          channels: $channels
          input_ch: $input_ch
          metertype: $metertype
          parentid: $parentid
        }
      ) {
        returning {
          id
          parentid
          metertype
        }
      }
    }
  `,
  variables: {
    parentid: 99000,
    address: '33',
    bitrate: '123456',
    input_ch: 7,
    metertype: 'type',
    channels: 6,
  },
  dataString: 'insert_inventorydb_pu',
}

/** set new akb */
export const insertNewAKB = {
  query: gql`
    mutation insertNewAKB(
      $bnum: Int
      $btype: String
      $capacity: numeric
      $inuse: Boolean
      $parentid: Int
      $npower: numeric
      $model: String
    ) {
      insert_inventorydb_akb(
        objects: {
          bnum: $bnum
          btype: $btype
          capacity: $capacity
          inuse: $inuse
          parentid: $parentid
          npower: $npower
          model: $model
        }
      ) {
        returning {
          id
          parentid
        }
      }
    }
  `,
  variables: {
    parentid: 99001,
    bnum: 555,
    btype: '44444',
    capacity: 432423,
    inuse: true,
    npower: 21,
    model: 'trtrt',
  },
  dataString: 'insert_inventorydb_akb',
}

/** set new ACDC */
export const insertNewACDC = {
  query: gql`
    mutation insertNewACDC($etype: String, $parentid: Int, $title: String) {
      insert_inventorydb_acdc(
        objects: { etype: $etype, parentid: $parentid, title: $title }
      ) {
        returning {
          parentid
          title
          id
        }
      }
    }
  `,
  variables: {
    parentid: 99001,
    etype: 'fdsfsdfsd',
    title: 'sfsfsdfsdfsdfdfsd',
  },
  dataString: 'insert_inventorydb_acdc',
}
