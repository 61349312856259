import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { queryInventorydbMeter } from '../../graphql'
// import { Card, CardBody, CardHeader } from '../../components'
// import styled from 'styled-components'
// import moment from 'moment'
import { CheckParametersMeter } from './CheckParametersMeter'

export const CheckMeters = ({ objectid }) => {
  const [meters, setMeters] = useState()

  useQuery(queryInventorydbMeter.query, {
    variables: { id: objectid },
    onCompleted: (data) => {
      setMeters(data[queryInventorydbMeter.dataString])
    },
  })

  return (
    <div>
      {meters &&
        meters.map((item, i) => {
          return (
            <CheckParametersMeter
              key={i}
              meter={item}
              input_ch={item.input_ch}
            />
          )
        })}
    </div>
  )
}

CheckMeters.propTypes = { objectid: PropTypes.number }
