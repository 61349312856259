import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import './index.scss'
import { useTranslation } from 'react-i18next'
import TableObjectsRow from './TableObjectsRow'

const StyledThHidden = styled.th`
  @media (max-width: 840px) {
    display: none;
  }
`

export const TableObjects = ({ data = [], onSort, sort, sortField }) => {
  const { t } = useTranslation()

  const ButtonSort = ({ handleClick, value, sort }) => {
    const HandleClickSort = (event, value) => {
      event.preventDefault()
      handleClick(value)
    }
    return (
      <button
        type="button"
        className="btn btn-outline-secondary btn-sm"
        onClick={(event) => HandleClickSort(event, value)}>
        {sortField === value ? <small>{sort}</small> : '⇅'}
      </button>
    )
  }

  return (
    <div className="table-objects">
      <table>
        <thead className="text-center">
          <tr className="head-row-1">
            <th className="">
              {t('Customer')}{' '}
              <ButtonSort handleClick={onSort} value={'customer'} sort={sort} />
            </th>
            <th className="">
              {t('Address')}{' '}
              <ButtonSort handleClick={onSort} value={'street'} sort={sort} />
            </th>
            <th className="">
              {t('State')}{' '}
              <ButtonSort handleClick={onSort} value={'state'} sort={sort} />
            </th>
            <th className="">
              {t('Identifier')}{' '}
              <ButtonSort
                handleClick={onSort}
                value={'billingcode'}
                sort={sort}
              />
            </th>
            <th className="">
              {/* {t('Vendor')}{' '} */}
              <ButtonSort handleClick={onSort} value={'statusid'} sort={sort} />
            </th>
            <StyledThHidden>Действия</StyledThHidden>
          </tr>
        </thead>

        <tbody>
          {data.length === 0 ? (
            <tr>
              <td colSpan="9" className=" text-center">
                Нет данных
              </td>
            </tr>
          ) : (
            data.map((item, i) => <TableObjectsRow row={item} key={i} />)
          )}
        </tbody>
      </table>
    </div>
  )
}

TableObjects.propTypes = {
  handleClick: PropTypes.array,
  value: PropTypes.string,
  sort: PropTypes.string,
}
