import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledDiv = styled.div`
  width: 100%;
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: start;
  }
`

const StyledSpan = styled.span`
  margin-right: 1em;
  font-weight: bold;

  @media (max-width: 640px) {
    margin-right: 0;
    margin-bottom: 0.5em;
  }
`

const StyledInput = styled.input`
  /* width: 100%; */
  min-height: 2.5em;
  padding: 0;
  font-size: 0.8125rem;
  border: 1px solid #e1e5eb;
  font-weight: 300;
  will-change: border-color, box-shadow;
  border-radius: 0.25rem;
  box-shadow: none;
  margin-right: 1em;

  @media (max-width: 640px) {
    min-height: 2.4em;
    margin-right: 0;
    margin-bottom: 1em;
  }
`

export const InputIdentifier = ({ setIdentifier }) => {
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (!inputValue) return
    setIdentifier(inputValue.trim())
  }, [inputValue, setIdentifier])

  const onChangeValue = (e) => {
    setInputValue(e.target.value)
  }

  return (
    <StyledDiv>
      <StyledSpan>Идентификатор объекта:</StyledSpan>
      <StyledInput
        className="input"
        type="text"
        value={inputValue}
        placeholder=""
        onChange={onChangeValue}
      />
    </StyledDiv>
  )
}

InputIdentifier.propTypes = { setIdentifier: PropTypes.func }
