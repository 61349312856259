import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { queryDictMeterAddress } from '../../graphql'
import { DropDownVariables } from './DropDownVariables'

export const SelectMeter = ({ setChannel, setMeterType, setAddress }) => {
  const [arrayElements, setArrayElements] = useState()
  const [element, setElement] = useState()

  useEffect(() => {
    if (!element) return
    setChannel(element.channels)
    setMeterType(element.metertype)
    setAddress(element.address)
  }, [element, setAddress, setChannel, setMeterType])

  useQuery(queryDictMeterAddress.query, {
    onCompleted: (data) => {
      const arr = data[queryDictMeterAddress.dataString].map((item) => {
        const obj = Object.assign(
          {},
          { title: `${item.metertype}, адрес ${item.address}` },
          item
        )
        return obj
      })
      setArrayElements(arr)
    },
  })

  if (!arrayElements) return <></>

  return (
    <DropDownVariables
      title="Счетчик"
      listVar={arrayElements}
      setSelected={setElement}
    />
  )
}

SelectMeter.propTypes = { setChannel: PropTypes.func }
