import React, { useState, useContext, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardHeader,
  // AlertMeter,
  InputPhone,
  ResetController,
  Available,
} from '../../components'
import styled from 'styled-components'
import { ObjectStatus } from './ObjectStatus'
import { SelectCustomer } from './SelectCustomer'
import { SelectObject } from './SelectObject'
import { ContextUser } from '../../context'
import { Button } from '../Buttons'

const StyledCardHeader = styled(CardHeader)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
`

export const PanelInstaller = ({ object, setObject, title }) => {
  let history = useHistory()
  const context = useContext(ContextUser)
  const [customerid, setCustomerid] = useState()
  const [resetPhone, setResetPhone] = useState(false)

  const fetchObject = useCallback(() => {
    setObject()
  }, [setObject])

  /**
   * reset object when change customer
   */
  useEffect(() => {
    fetchObject()
  }, [customerid, fetchObject])

  const HandleClickRow = (id) => {
    history.push(`/configure/${id}`)
  }

  return (
    <Card>
      <StyledCardHeader className="border-bottom">
        {object && <ObjectStatus objectid={object.id} />}
        {object && <ResetController setResetPhone={setResetPhone} />}
      </StyledCardHeader>
      <CardBody>
        <SelectCustomer setCustomerid={setCustomerid} />
        {customerid && (
          <SelectObject
            customerid={customerid}
            setObject={setObject}
            title={title}
          />
        )}
        {object && (
          <InputPhone
            objectid={object.id}
            resetPhone={resetPhone}
            setResetPhone={setResetPhone}
          />
        )}
        {object && (
          <Available rolesid={[1]}>
            <Button type="button" onClick={() => HandleClickRow(object.id)}>
              Конфигурация объекта
            </Button>
          </Available>
        )}
        {/* {object && <AlertMeter objectid={object.id} />} */}
      </CardBody>
    </Card>
  )
}

PanelInstaller.propTypes = {
  title: PropTypes.string,
  object: PropTypes.object,
  setObject: PropTypes.func,
}
