import { useState, useEffect } from 'react'
import { queryGetTocken } from '../graphql'
import { useQuery, useLazyQuery } from '@apollo/client'

// billingQuery

/**
 * return all object billing
 *
 * @requires billingQuery
 * @param {object} billingQuery - object conteining grephql query and name table
 *
 * @example variables: {
    billingQuery: queryAllObjectBilling,
  }
 *
 */
export const useBillingObject = ({ billingQuery, code }) => {
  const [tokenBilling, setToken] = useState()
  const [data, setData] = useState()

  /**query data from billing */
  const [gettingObject] = useLazyQuery(billingQuery.query, {
    onCompleted: (data) => {
      setData(data[billingQuery.dataString].data)
    },
    variables: { code, token: tokenBilling },
  })

  /** get token */
  useQuery(queryGetTocken.query, {
    onCompleted: (data) => {
      setToken(data.auth.access_token)
    },
  })

  /** kick query to data billing
   * await token and code
   */
  useEffect(() => {
    if (!tokenBilling) return
    if (!code) return
    gettingObject()
  }, [code, gettingObject, tokenBilling])

  if (!code) return
  return data
}

// // export const useBillingObjects = ({ billingQuery, ...rest }) => {
//     const [tokenBilling, setstate] = useState()
//     const [data, setData] = useState()
//     const { error, loading, data: dataToken } = useQuery(queryGetTocken.query)
//     const variabl = { ...rest, token: tokenBilling }
//     const [gettingObject, { data: dataBilling }] = useLazyQuery(
//       billingQuery.query,
//       {
//         variables: variabl,
//       }
//     )
//     // console.log('useBillingObjects = ', variabl, error, loading, dataBilling)
//     useEffect(() => {
//       if (!dataBilling) return
//       if (dataBilling[billingQuery.dataString].length === 0) return
//       setData(dataBilling[billingQuery.dataString].data)
//     }, [dataBilling])

//     useEffect(() => {
//       if (!dataToken) return
//       setstate(dataToken.auth.access_token)
//       gettingObject()
//     }, [dataToken])
//     return data
//   // }
