import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { queryDictPowerSystem } from '../../graphql'
import { DropDownVariables } from './DropDownVariables'

export const SelectPowerSystem = ({ setPowerSystem }) => {
  const [arrayElements, setArrayElements] = useState()
  const [element, setElement] = useState()

  useEffect(() => {
    if (!element) return
    setPowerSystem(element.title)
  }, [element, setPowerSystem])

  useQuery(queryDictPowerSystem.query, {
    onCompleted: (data) => {
      setArrayElements(data[queryDictPowerSystem.dataString])
    },
  })

  if (!arrayElements) return <></>

  return (
    <DropDownVariables
      title="ЭПУ"
      listVar={arrayElements}
      setSelected={setElement}
    />
  )
}

SelectPowerSystem.propTypes = { setPowerSystem: PropTypes.func }
