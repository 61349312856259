import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types'
import { ChannelCard } from '../../components'
import styled from 'styled-components'
import { useSubscription } from '@apollo/client'
import { subInventorydbChannel } from '../../graphql'

const StyledDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  grid-gap: 1em;

  @media (max-width: 840px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
`

export const ChannelsPanel = ({ meter }) => {
  const [channels, setChannels] = useState()

  const { data: dataChannels } = useSubscription(subInventorydbChannel.query, {
    variables: { id: meter.id },
  })

  useEffect(() => {
    if (!dataChannels) return
    // if (dataChannels[subInventorydbChannel.dataString].length === 0) return
    const arr = dataChannels[subInventorydbChannel.dataString]
    setChannels(arr)

    // // set meters Address
    // const address = arr.map((item) => item.address)
    // setChannelAddress(address)
  }, [dataChannels])

  if (!channels) return <></>

  return (
    <StyledDiv>
      {channels.map((item, i) => {
        return (
          <ChannelCard key={i} channel={item} addressMeter={meter.address} />
        )
      })}
    </StyledDiv>
  )
}

ChannelsPanel.propTypes = {}
