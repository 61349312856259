import { useState, useEffect, useCallback } from 'react'
import { queryGetTocken } from '../graphql/queriesBilling'
import { useQuery, useLazyQuery } from '@apollo/client'

/**
 * return all object billing
 *
 * @requires queryBilling
 * @param {object} queryBilling - object conteining grephql query and name table
 *
 * @example variables: {
    queryBilling: queryAllObjectBilling,
  }
 *
 */
export const useBillingToken = ({ queryBilling, ...rest }) => {
  const [data, setData] = useState()
  const [token, setToken] = useState()

  // получаем токен
  useQuery(queryGetTocken.query, {
    onCompleted: (data) => {
      setToken(data.auth.access_token)
    },
  })

  // получаем данные из бд
  const [startQuery] = useLazyQuery(queryBilling.query, {
    onCompleted: (data) => {
      setData(data[queryBilling.dataString].data)
    },
  })

  const fetchQuery = useCallback(
    (t) => {
      startQuery({ variables: { token: t, ...rest } })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startQuery]
  )

  //  при получении токена делаем запрос в бд
  useEffect(() => {
    if (!token) return
    fetchQuery(token)
  }, [token, fetchQuery])

  return data
}
